
import { ContactKind, contactKindName } from "@/models";
import Vue from "vue";
export default Vue.extend({
  props: {
    kind: {
      type: String as () => ContactKind
    }
  },
  computed: {
    ContactKind: () => ContactKind,
    color() {
      switch (this.kind) {
        case ContactKind.Cold:
          return "primary";
        case ContactKind.Scheduled:
          return "secondary";
        case ContactKind.Inertia:
          return "accent";
        default:
          return "primary";
      }
    },
    text() {
      return contactKindName(this.kind);
    },
    icon() {
      switch (this.kind) {
        case ContactKind.Cold:
          return "mdi-phone-outgoing-outline";
        case ContactKind.Scheduled:
          return "mdi-calendar-arrow-right";
        case ContactKind.Inertia:
          return "mdi-phone-incoming-outline";
        default:
          return "No definido";
      }
    }
  }
});
