
import Vue from "vue";
import { GeographicZone, ValidationErrors } from "@/models";
import {
  createGeographicZone,
  deleteGeographicZone,
  editGeographicZone,
  getGeographicZones
} from "@/api";
import { AxiosError, AxiosResponse } from "axios";
import ButtonCircle, { Kind } from "@/components/ButtonCircle.vue";

interface EditableGeographicZone extends GeographicZone {
  editing: boolean;
}

interface GeographicZonesState {
  geographicZones: EditableGeographicZone[];
  newGeographicZone: GeographicZone;
  creationErrors: ValidationErrors;
  editionErrors: ValidationErrors;
  editing: boolean;
}

export default Vue.extend({
  components: {
    ButtonCircle
  },
  data(): GeographicZonesState {
    return {
      geographicZones: [],
      newGeographicZone: {
        id: 0,
        name: "",
        deletable: false
      },
      creationErrors: {},
      editionErrors: {},
      editing: false
    };
  },
  computed: {
    Kind: () => Kind
  },
  mounted() {
    this.getGeographicZones();
  },
  methods: {
    getGeographicZones() {
      getGeographicZones().then((response: AxiosResponse<GeographicZone[]>) => {
        this.geographicZones = response.data.map(x => {
          return {
            ...x,
            editing: false
          };
        });
      });
    },
    createGeographicZone() {
      createGeographicZone(this.newGeographicZone)
        .then(() => {
          this.newGeographicZone = {
            id: 0,
            name: "",
            deletable: false
          };
          this.getGeographicZones();
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.creationErrors = { ...error.response.data.errors };
          }
        });
    },
    deleteGeographicZone(geographicZone: GeographicZone) {
      deleteGeographicZone(geographicZone).then(() => {
        this.getGeographicZones();
      });
    },
    startEdition(geographicZone: EditableGeographicZone) {
      geographicZone.editing = true;
      this.editing = true;
    },
    editGeographicZone(geographicZone: EditableGeographicZone) {
      editGeographicZone(geographicZone)
        .then(() => {
          this.getGeographicZones();
          this.editing = false;
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.editionErrors = { ...error.response.data.errors };
          }
        });
    }
  }
});
