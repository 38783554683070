
import { Client } from "@/models";
import Vue from "vue";
export default Vue.extend({
  props: {
    client: {
      type: Object as () => Client
    }
  }
});
