
import { getContactTypes, getSalespersonTypes, setSalespersonTypeActivationStatus } from "@/api";
import { ContactType, SalespersonType, SnackbarColor } from "@/models";
import { MutationTypes } from "@/store";
import { AxiosResponse } from "axios";
import Vue from "vue";
import Help from "@/components/Help.vue";

interface ComponentState {
  salespersonTypes: SalespersonType[];
  loading: boolean;
  contactTypes: ContactType[];
}


export default Vue.extend({
  components: {
    Help,
  },
  data(): ComponentState {
    return {
      salespersonTypes: [],
      loading: true,
      contactTypes: []
    };
  },
  mounted() {
    this.getSalespersonTypes();
    getContactTypes().then((response: AxiosResponse<ContactType[]>) => {
      this.contactTypes = response.data;
    });
    // TODO: handle failures
  },
  methods: {
    getSalespersonTypes() {
      this.loading = true;
      getSalespersonTypes(true)
        .then((response: AxiosResponse<SalespersonType[]>) => {
          this.salespersonTypes = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            color: SnackbarColor.error,
            text: `Ha habido un error consultando los datos.`
          });
          this.loading = false;
        });
    },
    setActivationStatus(type: SalespersonType) {
      this.loading = true;
      setSalespersonTypeActivationStatus(type, type.isActive)
        .then(() => {
          this.loading = false;
          let text = type.isActive ? "activado" : "desactivado";
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            color: SnackbarColor.success,
            text: `${type.name} ${text} exitosamente.`
          });
        })
        .catch(() => {
          this.loading = false;
          let text = type.isActive ? "activando" : "desactivando";
          type.isActive = !type.isActive;
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            color: SnackbarColor.error,
            text: `Ha habido un error ${text} este tipo de vendedor.`
          });
        });
    }
  }
});
