
import { GoalAchievments, RoleName, roleNameString } from "@/models";
import { es } from "@/config";
import VueApexCharts from "vue-apexcharts";
import Vue from "vue";
import {
  getBDNQuotesCount,
  getClientsCount,
  getCriticalClientsCount,
  getGoalAchievments,
  getTodaysScheduledContactsCount
} from "@/api";
import { AxiosResponse } from "axios";
import ButtonToqui, { Kind as ToquiKind } from "@/components/ButtonToqui.vue";
import dayjs from "dayjs";
import numeral from "numeral";
import { MutationTypes } from "@/store";

export default Vue.extend({
  components: {
    VueApexCharts,
    ButtonToqui
  },
  data() {
    return {
      clientsCount: 0,
      criticalClientsCount: 0,
      todayScheduledContacts: 0,
      bdnQuotes: "",
      series: [
        {
          name: "Metas",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
          name: "Ventas",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      ],
      chartOptions: {
        chart: {
          width: "100%",
          type: "line",
          zoom: {
            enabled: false
          },
          locales: [es],
          defaultLocale: "es"
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight"
        },
        title: {
          text: `Cumplimiento de metas mensuales [${this.$store.state.parameters.currency}]`,
          align: "left"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: es.options.shortMonths
        },
        yaxis: {
          labels: {
            formatter: function(value: number) {
              return numeral(value).format();
            }
          }
        }
      }
    };
  },
  computed: {
    largeViewport(): boolean {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
        case "md":
          return false;
        case "lg":
        case "xl":
        default:
          return true;
      }
    },
    mediumViewport(): boolean {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return false;
        case "md":
        case "lg":
        case "xl":
        default:
          return true;
      }
    },
    roles(): string {
      return this.$store.state.user.roles
        .map((x: RoleName) => roleNameString(x))
        .join(", ");
    },
    ToquiKind: () => ToquiKind
  },
  mounted() {
    getClientsCount().then(
      (response: AxiosResponse<number>) => (this.clientsCount = response.data)
    );
    getCriticalClientsCount().then(
      (response: AxiosResponse<number>) =>
        (this.criticalClientsCount = response.data)
    );
    getTodaysScheduledContactsCount().then(
      (response: AxiosResponse<number>) =>
        (this.todayScheduledContacts = response.data)
    );
    getBDNQuotesCount().then(
      (response: AxiosResponse<number>) =>
        (this.bdnQuotes = numeral(response.data).format())
    );
    const currentDate = dayjs();
    getGoalAchievments(currentDate.year()).then(
      (response: AxiosResponse<GoalAchievments>) => {
        // Esto se tiene que hacer de esta manera para gatillar la actualización automática del gráfico.
        // El watcher está sobre el prop 'series'.
        this.series = [
          {
            name: "Metas",
            data: response.data.goals
          },
          {
            name: "Ventas",
            data: response.data.sales
          }
        ];
        this.chartOptions.xaxis.categories = response.data.labels;
      }
    );
  },
  methods: {
    showDay() {
      this.$store.commit(MutationTypes.DISPLAY_DAY_CALENDAR);
    }
  }
});
