
import {
  addGapDocument,
  createQuote,
  deleteQuote,
  editQuote,
  getClientNexuses,
  getPortfolios,
  getQuoteContacts,
  getQuoteGapDocuments,
  getQuotes,
  getQuoteSales,
  getQuotesExcel,
  loseQuote
} from "@/api";
import ButtonToqui, { Kind as ToquiKind } from "@/components/ButtonToqui.vue";
import ButtonCircle, { Kind } from "@/components/ButtonCircle.vue";
import Modal from "@/components/Modal.vue";
import QuoteStatusChip from "@/components/QuoteStatusChip.vue";
import {
  Contact,
  Nexus,
  PaginatedResult,
  Portfolio,
  GapDocument,
  GapDocumentData,
  Quote,
  QuoteData,
  QuotesQuery,
  QuoteStatus,
  RoleName,
  Sale,
  SnackbarColor,
  ValidationErrors
} from "@/models";
import { AxiosError, AxiosResponse } from "axios";
import QuoteForm from "@/views/quotes/QuoteForm.vue";
import GapDocumentForm from "@/views/gap_documents/GapDocumentForm.vue";
import ContextMenu from "@/components/ContextMenu.vue";
import ContextMenuOption from "@/components/ContextMenuOption.vue";
import dayjs from "dayjs";
import Vue from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import { MutationTypes } from "@/store";
import numeral from "numeral";
import { authorizeRoles } from "@/helpers";

export interface QuotesTableData {
  // Table data, filters, pagination and errors.
  quotes: Quote[];
  totalQuotes: number;
  loading: boolean;
  options: DataOptions;
  headers: DataTableHeader[];
  search?: string;
  quoteStatus?: QuoteStatus;
  error: string | null;
  portfoliosForFilter: Portfolio[];
  portfolioId: number;

  // Quote creation.
  showCreateForm: boolean;
  creating: boolean;

  // Quote edition.
  showEditForm: boolean;
  editedQuoteId: number;
  editing: boolean;

  // Quote deletion.
  showDeleteModal: boolean;
  deletedQuoteId: number;
  deleting: boolean;

  // Quote losing.
  showLoseModal: boolean;
  lostQuoteId: number;
  losing: boolean;

  // Quote form.
  quoteData: QuoteData;

  // Nexus selection (required to display contact form).
  showNexusModal: boolean;
  nexuses: Nexus[];
  followUpNexusId: number;
  followUpQuoteId: number;
  isScheduled: boolean;

  // Purchase creation.
  showGapDocumentCreateForm: boolean;
  creatingGapDocument: boolean;
  gapDocumentQuoteId: number;
  gapDocumentQuoteValue: number;
  gapDocumentQuoteGapDocumentsValue: number;

  // Purchase order form.
  gapDocumentData: GapDocumentData;

  // Validation errors (for all forms and requests).
  validationErrors: ValidationErrors;

  // Display quote contacts.
  showQuoteContacts: boolean;
  quoteContacts: Contact[];

  // Display quote purchase orders.
  showQuoteGapDocuments: boolean;
  quoteGapDocuments: GapDocument[];

  // Display quote sales.
  showQuoteSales: boolean;
  quoteSales: Sale[];

  // Counts
  counts: { [key: string]: number };
  computedValues: { [key: string]: number };

  // Excel export
  exporting: boolean;
}

const initialOptions: DataOptions = {
  sortBy: ["value"],
  sortDesc: [true],
  page: 1,
  itemsPerPage: 10,
  groupBy: [],
  groupDesc: [false],
  multiSort: false,
  mustSort: true
};

const defaultQuoteData: QuoteData = {
  code: "",
  value: 0,
  date: dayjs(),
  clientId: 0,
  portfolioId: 0,
  forProject: false,
  productsGroupId: 0,
  description: ""
};

const defaultGapDocumentData: GapDocumentData = {
  code: "",
  value: 0,
  date: dayjs()
};

export default Vue.extend({
  components: {
    ButtonToqui,
    ButtonCircle,
    Modal,
    QuoteForm,
    GapDocumentForm,
    QuoteStatusChip,
    ContextMenu,
    ContextMenuOption
  },
  data(): QuotesTableData {
    return {
      totalQuotes: 0,
      quotes: [],
      loading: true,
      search: "",
      options: initialOptions,
      error: null,
      portfoliosForFilter: [],
      portfolioId: 0,
      headers: [
        {
          text: "Código",
          align: "start",
          sortable: false,
          value: "code"
        },
        {
          text: "Cliente",
          align: "start",
          sortable: false,
          value: "client",
          width: "25%"
        },
        {
          text: `Valores [${this.$store.state.parameters.currency}]`,
          align: "start",
          sortable: true,
          value: "value",
          width: "25%"
        },
        {
          text: "Fecha de Emisión",
          align: "start",
          sortable: true,
          value: "date"
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: "actions"
        }
      ],
      showCreateForm: false,
      creating: false,
      showEditForm: false,
      editedQuoteId: 0,
      editing: false,
      showDeleteModal: false,
      deletedQuoteId: 0,
      deleting: false,
      showLoseModal: false,
      lostQuoteId: 0,
      losing: false,
      quoteData: { ...defaultQuoteData },
      validationErrors: {},
      showNexusModal: false,
      nexuses: [],
      followUpNexusId: 0,
      followUpQuoteId: 0,
      quoteStatus: QuoteStatus.BDN,
      showGapDocumentCreateForm: false,
      creatingGapDocument: false,
      gapDocumentQuoteId: 0,
      gapDocumentQuoteValue: 0,
      gapDocumentQuoteGapDocumentsValue: 0,
      gapDocumentData: { ...defaultGapDocumentData },
      showQuoteContacts: false,
      quoteContacts: [],
      showQuoteGapDocuments: false,
      quoteGapDocuments: [],
      showQuoteSales: false,
      quoteSales: [],
      isScheduled: false,
      counts: {
        BDP: 0,
        BDN: 0,
        Gap: 0,
        Billed: 0,
        Lost: 0,
        Expired: 0
      },
      computedValues: {
        QuotesValue: 0,
        GapValue: 0,
        SalesValue: 0,
        GapDocumentsValue: 0
      },
      exporting: false
    };
  },
  mounted() {
    this.getDataFromApi();
    getPortfolios().then((response: AxiosResponse<Portfolio[]>) => {
      // This map is to create a separate array than the used to filter this view by portfolio.
      this.portfoliosForFilter = response.data;
      this.portfoliosForFilter.unshift({
        id: 0,
        name: "Todas las carteras",
        clientsCount: 0
      });
    });
  },
  methods: {
    authorizeRoles: (roles: RoleName[]): boolean => authorizeRoles(roles),
    getDataFromApi(resetPage = true) {
      if (resetPage) this.options.page = 1;
      this.loading = true;
      this.error = null;
      const params: QuotesQuery = {
        page: this.options.page,
        resultsPerPage: this.options.itemsPerPage,
        status: this.quoteStatus,
        text: this.search,
        portfolioId: this.portfolioId === 0 ? undefined : this.portfolioId,
        sortBy: this.options.sortBy[0],
        sortDesc: this.options.sortDesc[0]
      };
      getQuotes(params)
        .then((response: AxiosResponse<PaginatedResult<Quote>>) => {
          this.quotes = response.data.data;
          this.totalQuotes = response.data.totalCount;
          this.counts = response.data.counts;
          this.computedValues = response.data.computedValues;
          this.loading = false;
        })
        .catch((error: AxiosError) => {
          console.log(error.config);
          this.quotes = [];
          this.totalQuotes = 0;
          this.loading = false;
          this.error = "Ha habido un error consultando los datos solicitados.";
        });
    },
    createQuote() {
      this.creating = true;
      createQuote(this.quoteData)
        .then(() => {
          this.closeCreationForm();
          this.getDataFromApi();
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.validationErrors = error.response.data.errors;
          }
        })
        .finally(() => {
          this.creating = false;
        });
    },
    closeCreationForm() {
      this.showCreateForm = false;
      this.creating = false;
      this.quoteData = {
        ...defaultQuoteData,
        portfolioId: this.quoteData.portfolioId,
        clientId: this.quoteData.clientId
      };
      this.validationErrors = {};
    },
    startEdition(quote: Quote) {
      this.showEditForm = true;
      this.quoteData = {
        code: quote.code,
        value: quote.value,
        date: quote.date,
        clientId: quote.client.id,
        portfolioId: quote.portfolio.id,
        forProject: quote.status == QuoteStatus.BDP,
        productsGroupId: quote.productsGroup.id,
        description: quote.description
      };
      this.editedQuoteId = quote.id;
    },
    editQuote() {
      this.editing = true;
      editQuote(this.editedQuoteId, this.quoteData)
        .then(() => {
          this.closeEditForm();
          this.getDataFromApi();
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.validationErrors = error.response.data.errors;
          }
        })
        .finally(() => {
          this.editing = false;
        });
    },
    closeEditForm() {
      this.showEditForm = false;
      this.editing = false;
      this.quoteData = {
        ...defaultQuoteData,
        portfolioId: this.quoteData.portfolioId,
        clientId: this.quoteData.clientId
      };
      this.editedQuoteId = 0;
      this.validationErrors = {};
    },
    startDeletion(quote: Quote) {
      this.showDeleteModal = true;
      this.deletedQuoteId = quote.id;
    },
    deleteQuote() {
      this.deleting = true;
      deleteQuote(this.deletedQuoteId)
        .then(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `Cotización eliminada exitosamente.`,
            color: SnackbarColor.success
          });
          this.getDataFromApi();
        })
        .catch(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `No fue posible eliminar la cotización.`,
            color: SnackbarColor.error
          });
        })
        .finally(() => {
          this.showDeleteModal = false;
          this.deletedQuoteId = 0;
          this.deleting = false;
        });
    },
    startLoseQuote(quote: Quote) {
      this.showLoseModal = true;
      this.lostQuoteId = quote.id;
    },
    loseQuote() {
      this.losing = true;
      loseQuote(this.lostQuoteId)
        .then(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `El estado de la cotización ha sido cambiado a "Perdida"`,
            color: SnackbarColor.success
          });
          this.getDataFromApi();
        })
        .catch(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `No fue posible cambiar el estado de la cotización a "Perdida"`,
            color: SnackbarColor.error
          });
        })
        .finally(() => {
          this.showLoseModal = false;
          this.lostQuoteId = 0;
          this.losing = false;
        });
    },
    startFollowUp(quote: Quote, isScheduled: boolean) {
      this.showNexusModal = true;
      this.followUpQuoteId = quote.id;
      this.isScheduled = isScheduled;
      getClientNexuses(quote.client).then(
        (response: AxiosResponse<Nexus[]>) => {
          this.nexuses = response.data;
          if (this.nexuses.length > 0)
            this.followUpNexusId = this.nexuses[0].id;
        }
      );
      // TODO: handle failures
    },
    filterBy(type: QuoteStatus | undefined = undefined) {
      this.quoteStatus = type;
      this.getDataFromApi();
    },
    followUp() {
      if (this.isScheduled) {
        this.$store.commit(MutationTypes.SHOW_SCHEDULE_CONTACT_FORM, {
          nexusId: this.followUpNexusId,
          quoteId: this.followUpQuoteId
        });
      } else {
        this.$store.commit(MutationTypes.SHOW_INFORM_UNSCHEDULED_CONTACT_FORM, {
          nexusId: this.followUpNexusId,
          quoteId: this.followUpQuoteId
        });
      }
      this.showNexusModal = false;
    },
    startGapDocumentCreation(quote: Quote) {
      this.showGapDocumentCreateForm = true;
      this.gapDocumentQuoteId = quote.id;
      this.gapDocumentQuoteValue = quote.value;
      this.gapDocumentQuoteGapDocumentsValue = quote.gapDocumentsValue;
    },
    addGapDocument() {
      this.creatingGapDocument = true;
      addGapDocument(this.gapDocumentQuoteId, this.gapDocumentData)
        .then(() => {
          this.closeGapDocumentCreationForm();
          this.getDataFromApi();
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.validationErrors = error.response.data.errors;
          }
        })
        .finally(() => {
          this.creatingGapDocument = false;
        });
    },
    closeGapDocumentCreationForm() {
      this.showGapDocumentCreateForm = false;
      this.gapDocumentQuoteId = 0;
      this.gapDocumentQuoteValue = 0;
      this.gapDocumentQuoteGapDocumentsValue = 0;
      this.creatingGapDocument = false;
      this.validationErrors = {};
      this.gapDocumentData = { ...defaultGapDocumentData };
    },
    displayQuoteContacts(quote: Quote) {
      this.showQuoteContacts = true;
      getQuoteContacts(quote.id).then((response: AxiosResponse<Contact[]>) => {
        this.quoteContacts = response.data;
      });
      // TODO: handle failures
    },
    displayQuoteGapDocuments(quote: Quote) {
      this.showQuoteGapDocuments = true;
      getQuoteGapDocuments(quote.id).then(
        (response: AxiosResponse<GapDocument[]>) => {
          this.quoteGapDocuments = response.data;
        }
      );
      // TODO: handle failures
    },
    displayQuoteSales(quote: Quote) {
      this.showQuoteSales = true;
      getQuoteSales(quote.id).then((response: AxiosResponse<Sale[]>) => {
        this.quoteSales = response.data;
      });
      // TODO: handle failures
    },
    downloadExcel() {
      this.exporting = true;
      const params: QuotesQuery = {
        page: this.options.page,
        resultsPerPage: this.options.itemsPerPage,
        status: this.quoteStatus,
        text: this.search
      };
      getQuotesExcel(params).finally(() => {
        this.exporting = false;
      });
    },
    numeral
  },
  computed: {
    ToquiKind: () => ToquiKind,
    Kind: () => Kind,
    QuoteStatus: () => QuoteStatus,
    RoleName: () => RoleName,
    showAll(): boolean {
      return this.quoteStatus === undefined;
    },
    showBDP(): boolean {
      return this.quoteStatus === QuoteStatus.BDP;
    },
    showBDN(): boolean {
      return this.quoteStatus === QuoteStatus.BDN;
    },
    showGap(): boolean {
      return this.quoteStatus === QuoteStatus.Gap;
    },
    showBilled(): boolean {
      return this.quoteStatus === QuoteStatus.Billed;
    },
    showExpired(): boolean {
      return this.quoteStatus === QuoteStatus.Expired;
    },
    showLost(): boolean {
      return this.quoteStatus === QuoteStatus.Lost;
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi(false);
      },
      deep: true
    }
  }
});
