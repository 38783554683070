
import { forgotPassword } from "@/api";
import ButtonToqui, { Kind as ToquiKind } from "@/components/ButtonToqui.vue";
import { ForgotPasswordData } from "@/models";
import { AxiosError, AxiosResponse } from "axios";
import Vue from "vue";
export default Vue.extend({
  components: {
    ButtonToqui
  },
  data() {
    return {
      email: "",
      errors: {},
      failed: false,
      success: false,
      message: ""
    };
  },
  methods: {
    forgotPassword() {
      this.errors = {};
      this.failed = false;
      this.success = false;
      this.message = "";
      const forgotPasswordData: ForgotPasswordData = {
        email: this.email
      };
      forgotPassword(forgotPasswordData)
        .then((response: AxiosResponse<string>) => {
          this.message = response.data;
          this.success = true;
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.errors = error.response.data.errors;
            this.failed = true;
          } else if (
            error.response &&
            (error.response.status == 409 || error.response.status == 404)
          ) {
            this.message = error.response.data;
            this.failed = true;
          }
        });
    }
  },
  computed: {
    ToquiKind: () => ToquiKind,
    messageColor() {
      if (this.success) {
        return "secondary";
      }
      if (this.failed) {
        return "error";
      }
      return "primary";
    }
  }
});
