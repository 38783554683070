import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Welcome from '../views/Welcome.vue'
import Login from '../views/Login.vue'
import Clients from '../views/clients/Index.vue'
import CreateClient from '../views/clients/Create.vue'
import ClientDetails from '../views/clients/Details.vue'
import Strategy from '../views/strategy/Index.vue'
import Variables from '../views/strategy/Variables.vue'
import Portfolios from '../views/Portfolios.vue'
import Configuration from '../views/configuration/Index.vue'
import Users from '../views/users/Index.vue'
import Salesforce from '../views/salesforce/Index.vue'
import Quotes from '../views/quotes/Index.vue'
import GapDocuments from '../views/gap_documents/Index.vue'
import Sales from '../views/sales/Index.vue'
import Goals from '../views/goals/Index.vue'
import Account from '../views/account/Index.vue'
import Confirmations from '../views/confirmations/Index.vue'
import DailyReports from '../views/daily_reports/Index.vue'
import DailyReportDetails from '../views/daily_reports/Details.vue'
import AccountConfirmation from '../views/account/AccountConfirmation.vue'
import ForgotPassword from '../views/account/ForgotPassword.vue'
import PasswordReset from '../views/account/PasswordReset.vue'
import OperativeBalance from '../views/operative_balance/Index.vue'
import Test from '../views/Test.vue'
import store from '../store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome,
    meta: {
      breadcrumb: [
        {
          text: 'Escritorio'
        }
      ]
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {}
  },
  {
    path: '/clients',
    name: 'Clients',
    component: Clients,
    props: route => ({ critical: Boolean(route.query.critical) }),
    meta: {
      breadcrumb: [
        {
          text: 'Clientes'
        }
      ]
    }
  },
  {
    path: '/clients/create',
    name: 'CreateClient',
    component: CreateClient,
    meta: {
      breadcrumb: [
        {
          text: 'Clientes',
          to: '/clients',
          exact: true

        },
        {
          text: 'Crear'
        }
      ]
    }
  },
  {
    path: '/clients/:id',
    name: 'ClientDetails',
    component: ClientDetails,
    meta: {
      breadcrumb: [
        {
          text: 'Clientes',
          to: '/clients',
          exact: true
        },
        {
          text: 'Detalles'
        }
      ]
    }
  },
  {
    path: '/portfolios',
    name: 'Portfolios',
    component: Portfolios,
    meta: {
      breadcrumb: [
        {
          text: 'Carteras',
          disabled: true,
        }
      ]
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      breadcrumb: [
        {
          text: 'Usuarios',
        }
      ]
    }
  },
  {
    path: '/salesforce',
    name: 'Salesforce',
    component: Salesforce,
    meta: {
      breadcrumb: [
        {
          text: 'Vendedores',
        }
      ]
    }
  },
  {
    path: '/strategy',
    name: 'Strategy',
    component: Strategy,
    meta: {
      breadcrumb: [
        {
          text: 'Estrategia Operativa'
        }
      ]
    }
  },
  {
    path: '/strategy/variables',
    name: 'Variables',
    component: Variables,
    meta: {
      breadcrumb: [
        {
          text: 'Estrategia Operativa',
          to: '/strategy',
          exact: true
        },
        {
          text: 'Variables'
        }
      ]
    }
  },
  {
    path: '/configuration',
    name: 'Configuration',
    component: Configuration,
    meta: {
      breadcrumb: [
        {
          text: 'Configuración'
        }
      ]
    }
  },
  {
    path: '/quotes',
    name: 'Cotizaciones',
    component: Quotes,
    meta: {
      breadcrumb: [
        {
          text: 'Cotizaciones'
        }
      ]
    }
  },
  {
    path: '/gap_documents',
    name: 'Brecha',
    component: GapDocuments,
    meta: {
      breadcrumb: [
        {
          text: 'Brecha'
        }
      ]
    }
  },
  {
    path: '/sales',
    name: 'Ventas',
    component: Sales,
    meta: {
      breadcrumb: [
        {
          text: 'Ventas'
        }
      ]
    }
  },
  {
    path: '/goals',
    name: 'Metas',
    component: Goals,
    meta: {
      breadcrumb: [
        {
          text: 'Metas'
        }
      ]
    }
  },
  {
    path: '/account',
    name: 'Mi cuenta',
    component: Account,
    meta: {
      breadcrumb: [
        {
          text: 'Mi cuenta'
        }
      ]
    }
  },
  {
    path: '/confirmations',
    name: 'Confirmaciones pendientes',
    component: Confirmations,
    meta: {
      breadcrumb: [
        {
          text: 'Confirmaciones pendientes'
        }
      ]
    }
  },
  {
    path: '/daily_reports',
    name: 'Registro de Informes',
    component: DailyReports,
    meta: {
      breadcrumb: [
        {
          text: 'Registro de Informes'
        }
      ]
    }
  },
  {
    path: '/operative_balance',
    name: 'Balance Operativo',
    component: OperativeBalance,
    meta: {
      breadcrumb: [
        {
          text: 'Balance Operativo'
        }
      ]
    }
  },
  {
    path: '/daily_reports/:id',
    name: 'DailyReportDetails',
    component: DailyReportDetails,
    meta: {
      breadcrumb: [
        {
          text: 'Registro de Informes',
          to: '/daily_reports',
          exact: true
        },
        {
          text: 'Detalles'
        }
      ]
    }
  },
  {
    path: '/account_confirmation',
    name: 'Confirmación de cuenta',
    component: AccountConfirmation,
    meta: {
      breadcrumb: [
        {
          text: 'Confirmación de cuenta'
        }
      ],
      noLayout: true
    }
  },
  {
    path: '/forgot_password',
    name: '¿Olvidó su contraseña?',
    component: ForgotPassword,
    meta: {
      breadcrumb: [
        {
          text: '¿Olvidó su contraseña?'
        }
      ],
      noLayout: true
    }
  },
  {
    path: '/password_reset',
    name: 'Reestablecer contraseña',
    component: PasswordReset,
    meta: {
      breadcrumb: [
        {
          text: 'Reestablecer contraseña'
        }
      ],
      noLayout: true
    }
  },
  {
    path: '/test',
    name: 'Test',
    component: Test,
    meta: {
      breadcrumb: [
        {
          text: 'Test'
        }
      ]
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const publicRoutes = [
    'Login',
    'Confirmación de cuenta',
    '¿Olvidó su contraseña?',
    'Reestablecer contraseña'
  ];
  if (!publicRoutes.includes(to.name ?? '') && !store.state.authenticated) next({ name: 'Login'})
  else next()
})


export default router
