
import Vue from "vue";
export default Vue.extend({
  props: {
    title: String,
    text: String,
    width: {
      type: Number,
      default: 500
    }
  },
  data() {
    return {
      dialog: false
    };
  }
});
