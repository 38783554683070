
import { GapDocumentData, ValidationErrors } from "@/models";
import { rules } from "@/helpers";
import dayjs from "dayjs";
import NumberInput from "@/components/NumberInput.vue";
import Vue from "vue";

interface GapDocumentFormData {
  date: string;
  isSalesperson: boolean;
  dateMenu: boolean;
  rules: { [key: string]: (value: string) => boolean | string };
}

export default Vue.extend({
  components: {
    NumberInput
  },
  props: {
    gapDocument: {
      type: Object as () => GapDocumentData
    },
    errors: {
      type: Object as () => ValidationErrors
    }
  },
  data(): GapDocumentFormData {
    return {
      date: this.gapDocument.date.format("YYYY-MM-DD"),
      isSalesperson: this.$store.state.user.isSalesperson,
      dateMenu: false,
      rules
    };
  },
  computed: {
    dateFormatted(): string {
      return this.gapDocument.date.format("DD-MM-YYYY");
    }
  },
  watch: {
    date: function(): void {
      this.gapDocument.date = dayjs(`${this.date}T00:00:00`);
    }
  }
});
