
import { getObjectives, setObjectiveActivationStatus } from "@/api";
import { Objective, SnackbarColor } from "@/models";
import { MutationTypes } from "@/store";
import { AxiosResponse } from "axios";
import Help from "@/components/Help.vue"
import Vue from "vue";

interface ComponentState {
  objectives: Objective[];
  loading: boolean;
}

export default Vue.extend({
   components:{
    Help
  },
  data(): ComponentState {
    return {
      objectives: [],
      loading: true,
    };
  },
  mounted() {
    this.getObjectives();
  },
  methods: {
    getObjectives() {
      this.loading = true;
      getObjectives(true)
        .then((response: AxiosResponse<Objective[]>) => {
          this.objectives = response.data;
          console.log(response.data);
          this.loading = false;
        })
        .catch(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            color: SnackbarColor.error,
            text: `Ha habido un error consultando los datos.`
          });
          this.loading = false;
        });
    },
    setActivationStatus(type: Objective) {
      this.loading = true;
      setObjectiveActivationStatus(type, type.isActive)
        .then(() => {
          this.loading = false;
          let text = type.isActive ? "activado" : "desactivado";
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            color: SnackbarColor.success,
            text: `${type.name} ${text} exitosamente.`
          });
        })
        .catch(() => {
          this.loading = false;
          let text = type.isActive ? "activando" : "desactivando";
          type.isActive = !type.isActive;
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            color: SnackbarColor.error,
            text: `Ha habido un error ${text} este objetivo.`
          });
        });
    }
  }
});
