
import Vue from "vue";
import { Portfolio } from "@/models";

export default Vue.extend({
  props: {
    portfolio: Object as () => Portfolio,
    selected: Boolean
  }
});
