
import { getSalespeople } from "@/api";
import { Portfolio, Salesperson, ValidationErrors } from "@/models";
import { AxiosResponse } from "axios";
import Vue from "vue";

interface PortfolioFormData {
    salespeopleSelectData: { id: number | null, name: string, lastName: string, email: string }[];
}

export default Vue.extend({
  props: {
    portfolio: {
      type: Object as () => Portfolio
    },
    validationErrors: {
      type: Object as () => ValidationErrors
    }
  },
  data(): PortfolioFormData {
    return {
      salespeopleSelectData: []
    };
  },
  mounted() {
    getSalespeople().then((response: AxiosResponse<Salesperson[]>) => {
      this.salespeopleSelectData = response.data;
      this.salespeopleSelectData.unshift({
          id: null,
          name: 'Sin vendedor asignado',
          lastName: '',
          email: ''
      });
    });
  }
});
