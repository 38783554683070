
import Vue from "vue";
export default Vue.extend({
    props: {
        route: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        }
    },
    computed: {
        linkText() : string {
            if (this.$router.options.routes)
            {
                const bc = this.$router.options.routes.filter(x => x.path === this.route)[0].meta?.breadcrumb;
                if (bc) return bc[bc.length - 1].text;
            }
            return '';
        }
    }
});
