
import { mapState } from "vuex";
import Vue from "vue";
import * as signalR from "@microsoft/signalr";
import "animate.css";
import { backendUrl } from "@/api";

export default Vue.extend({
  computed: {
    ...mapState(["global"])
  },
  mounted() {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${backendUrl}/confirmationsHub`)
      .build();
    connection.on("TestHandler", (message: string) => {
      console.log("got here");
      console.log(message);
    });
    connection.start().catch(error => console.error(error));
  }
});
