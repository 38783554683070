
import Vue from "vue";
import {
  createBusinessSegment,
  deleteBusinessSegment,
  editBusinessSegment,
  getBrands,
  getBusinessSegments,
  getBusinessUnits,
  getGeographicZones
} from "@/api";
import {
  ActionError,
  Brand,
  BusinessSegment,
  BusinessUnit,
  GeographicZone,
  SnackbarColor,
  ValidationErrors
} from "@/models";
import { AxiosError, AxiosResponse } from "axios";
import Modal from "@/components/Modal.vue";
import BusinessSegmentForm from "./BusinessSegmentForm.vue";
import ButtonCircle, { Kind } from "@/components/ButtonCircle.vue";
import ButtonToqui, { Kind as ToquiKind } from "@/components/ButtonToqui.vue";
import SegmentPortfolios from "./SegmentPortfolios.vue";
import { DataOptions, DataTableHeader } from "vuetify";
import { MutationTypes } from "@/store";

interface BusinessSegmentData {
  businessSegments: BusinessSegment[];
  brands: Brand[];
  businessUnits: BusinessUnit[];
  geographicZones: GeographicZone[];
  showCreateForm: boolean;
  showEditForm: boolean;
  showDeleteDialog: boolean;
  formSegment: BusinessSegment;
  loading: boolean;
  creating: boolean;
  editing: boolean;
  deleting: boolean;
  validationErrors: ValidationErrors;
  dataOptions: DataOptions;
  headers: DataTableHeader[];
  deletionErrors: ActionError[];
  showDeletionErrors: boolean;
}

const defaultSegment: ExtendedBusinessSegment = {
  id: 0,
  name: "",
  portfolios: [],
  creatingPortfolio: false
};

interface ExtendedBusinessSegment extends BusinessSegment {
  creatingPortfolio: boolean;
}

const initialOptions: DataOptions = {
  sortBy: [],
  sortDesc: [false],
  page: 1,
  itemsPerPage: 10,
  groupBy: [],
  groupDesc: [false],
  multiSort: false,
  mustSort: true
};

export default Vue.extend({
  components: {
    Modal,
    BusinessSegmentForm,
    ButtonCircle,
    SegmentPortfolios,
    ButtonToqui
  },
  data(): BusinessSegmentData {
    return {
      businessSegments: [],
      brands: [],
      businessUnits: [],
      geographicZones: [],
      showCreateForm: false,
      showEditForm: false,
      showDeleteDialog: false,
      formSegment: { ...defaultSegment },
      loading: true,
      creating: false,
      editing: false,
      deleting: false,
      validationErrors: {},
      dataOptions: initialOptions,
      headers: [
        { text: "Segmento", value: "segment", width: "30%" },
        { text: "Carteras de clientes", value: "portfolios", width: "50%" },
        { text: "Acciones", value: "actions", width: "20%" }
      ],
      deletionErrors: [],
      showDeletionErrors: false,
    };
  },
  computed: {
    Kind: () => Kind,
    ToquiKind: () => ToquiKind
  },
  mounted() {
    this.getBusinessSegments();
    this.getBrands();
    this.getBusinessUnits();
    this.getGeographicZones();
  },
  methods: {
    // Business Segment related methods
    getBusinessSegments() {
      this.businessSegments = [];
      this.loading = true;
      getBusinessSegments().then(
        (response: AxiosResponse<BusinessSegment[]>) => {
          this.businessSegments = response.data.map(x => {
            return {
              ...x,
              creatingPortfolio: false
            }
          });
          this.loading = false;
        }
      );
    },
    createBusinessSegment() {
      this.creating = true;
      createBusinessSegment(this.formSegment)
        .then(() => {
          this.getBusinessSegments();
          this.showCreateForm = false;
          this.formSegment = { ...defaultSegment };
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.validationErrors = { ...error.response.data.errors };
          }
        })
        .finally(() => {
          this.creating = false;
        });
    },
    editBusinessSegment() {
      this.editing = true;
      editBusinessSegment(this.formSegment)
        .then(() => {
          this.getBusinessSegments();
          this.showEditForm = false;
          this.formSegment = { ...defaultSegment };
          this.editing = false;
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.validationErrors = { ...error.response.data.errors };
          }
          this.editing = false;
        });
    },
    deleteBusinessSegment() {
      this.deleting = true;
      deleteBusinessSegment(this.formSegment)
        .then(() => {
          this.getBusinessSegments();
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.deletionErrors = error.response.data.errors;
            this.showDeletionErrors = true;
          } else {
            this.$store.commit(MutationTypes.SHOW_SNACK, {
              text: "El segmento no ha podido ser eliminado.",
              color: SnackbarColor.error
            });
          }
        })
        .finally(() => {
          this.showDeleteDialog = false;
          this.deleting = false;
        });
    },
    // Parameter related methods.
    getBrands() {
      getBrands().then((response: AxiosResponse<Brand[]>) => {
        this.brands = response.data;
      });
    },
    getBusinessUnits() {
      getBusinessUnits().then((response: AxiosResponse<BusinessUnit[]>) => {
        this.businessUnits = response.data;
      });
    },
    getGeographicZones() {
      getGeographicZones().then((response: AxiosResponse<GeographicZone[]>) => {
        this.geographicZones = response.data;
      });
    },
    startCreation() {
      this.validationErrors = {};
      this.formSegment = { ...defaultSegment };
      this.showCreateForm = true;
    },
    startEdition(segment: BusinessSegment) {
      this.validationErrors = {};
      this.formSegment = { ...segment };
      this.showEditForm = true;
    },
    startDeletion(segment: BusinessSegment) {
      this.validationErrors = {};
      this.formSegment = { ...segment };
      this.showDeleteDialog = true;
    },
    closeModal() {
      this.showCreateForm = false;
      this.showEditForm = false;
      this.showDeleteDialog = false;
    },
    closeDeletionErrors() {
      this.deletionErrors = [];
      this.showDeletionErrors = false;
    }
  }
});
