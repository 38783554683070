
import { getContactTypes, setContactTypeActivationStatus } from "@/api";
import { ContactType, SnackbarColor } from "@/models";
import { MutationTypes } from "@/store";
import { AxiosResponse } from "axios";
import ContactKindChip from "@/components/ContactKindChip.vue";
import Help from "@/components/Help.vue";
import Vue from "vue";

interface ComponentState {
  contactTypes: ContactType[];
  loading: boolean;
}

export default Vue.extend({
  data(): ComponentState {
    return {
      contactTypes: [],
      loading: true
    };
  },
  components: {
    ContactKindChip,
    Help
  },
  mounted() {
    this.getContactTypes();
  },
  methods: {
    getContactTypes() {
      this.loading = true;
      getContactTypes(true)
        .then((response: AxiosResponse<ContactType[]>) => {
          this.contactTypes = response.data;
          console.log(response.data);
          this.loading = false;
        })
        .catch(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            color: SnackbarColor.error,
            text: `Ha habido un error consultando los datos.`
          });
          this.loading = false;
        });
    },
    setActivationStatus(type: ContactType) {
      this.loading = true;
      setContactTypeActivationStatus(type, type.isActive)
        .then(() => {
          this.loading = false;
          let text = type.isActive ? "activado" : "desactivado";
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            color: SnackbarColor.success,
            text: `${type.name} ${text} exitosamente.`
          });
        })
        .catch(() => {
          this.loading = false;
          let text = type.isActive ? "activando" : "desactivando";
          type.isActive = !type.isActive;
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            color: SnackbarColor.error,
            text: `Ha habido un error ${text} este tipo de contacto.`
          });
        });
    }
  }
});
