
import { Vue } from "vue-property-decorator";
import ClientsTable from "@/views/clients/ClientsTable.vue"; // @ is an alias to /src
import ButtonToqui, { Kind } from "@/components/ButtonToqui.vue";

export default Vue.extend({
  components: {
    ClientsTable,
    ButtonToqui
  },
  props: {
    critical: {
      type: Boolean,
      default: undefined
    }
  },
  computed: {
    Kind: () => Kind
  }
})
