import { CreateClientData, Facility, Nexus } from "./models";

export const defaultFacility: Facility = {
    id: 0,
    name: '',
    identifier: "",
    nexuses: [],
    isMain: false,
    isConfirmed: false,
    region: "",
    province: "",
    commune: "",
    countryId: 0,
    country: "",
    address: ""
}

export const defaultNexus: Nexus = {
    id: 0,
    name: "",
    lastName: "",
    email: "",
    phone: "",
    mobilePhone: "",
    position: "",
    officeNumber: "",
    isMain: false,
    isConfirmed: false,
    isActive: false
}

export const defaultCreateClientData: CreateClientData = {
    name: '',
    textFields: {},
    selectableFields: {},
    facility: { ...defaultFacility },
    nexus: { ...defaultNexus },
    portfolioIds: [],
    id: 0,
    portfolios: [],
    facilities: [],
    isConfirmed: false,
    isDeletable: false
}