
import {
  ClientsQuery,
  Client,
  Facility,
  Nexus,
  Portfolio,
  SnackbarColor,
  RoleName,
  PaginatedResult
} from "@/models";
import { PortfolioType } from "@/models";
import Vue from "vue";
import { DataOptions, DataTableHeader } from "vuetify/types";
import {
  addClientToPortfolios,
  confirmClient,
  deleteClient,
  getClientNexuses,
  getClients,
  getClientsExcel,
  getPortfolios,
  rejectClient
} from "@/api";
import { AxiosResponse } from "axios";
import PortfolioTypeChip from "@/components/PortfolioTypeChip.vue";
import Modal from "@/components/Modal.vue";
import EmptyCard from "@/components/EmptyCard.vue";
import ButtonCircle, { Kind } from "@/components/ButtonCircle.vue";
import ButtonToqui, { Kind as ToquiKind } from "@/components/ButtonToqui.vue";
import ContextMenu from "@/components/ContextMenu.vue";
import ContextMenuOption from "@/components/ContextMenuOption.vue";
import RequireConfirmation from "@/components/RequireConfirmation.vue";
import { MutationTypes } from "@/store";
import { authorizeRoles } from "@/helpers";

interface ExtendedClient extends Client {
  mainFacility?: Facility;
  mainNexus?: Nexus;
}

export interface ClientsTableData {
  // Table data.
  totalClients: number;
  clients: ExtendedClient[];
  loading: boolean;
  options: DataOptions;
  headers: DataTableHeader[];

  // Filters.
  search?: string;
  portfolioType?: PortfolioType;
  error: string | null;
  portfolioId: number;
  portfolios: Portfolio[];
  portfoliosForFilter: Portfolio[];
  isCritical?: boolean;

  // Set client portfolios.
  showPortfoliosModal: boolean;
  selectedClient: Client;
  selectedPortfolioIds: number[];
  switchingPortfolios: boolean;

  // Client confirmation.
  confirmedClientId: number;
  showConfirmationModal: boolean;

  // Client rejection.
  rejectedClientId: number;
  showRejectionModal: boolean;

  // Confirmation or rejection.
  processing: boolean;
  clientName: string;

  // Counts.
  counts: { [key: string]: number };

  // Exporting to excel
  exporting: boolean;

  // Nexus selection (required to display contact form).
  showNexusModal: boolean;
  nexuses: Nexus[];
  selectedNexusId: number;
  isScheduled: boolean;

  // Client deletion
  showDeleteModal: boolean;
  deleting: boolean;
  deletedClient: Client;
}

const initialOptions: DataOptions = {
  sortBy: [],
  sortDesc: [false],
  page: 1,
  itemsPerPage: 10,
  groupBy: [],
  groupDesc: [false],
  multiSort: false,
  mustSort: true
};

const defaultClient: Client = {
  id: 0,
  name: "",
  portfolios: [],
  facilities: [],
  isConfirmed: false,
  textFields: {},
  selectableFields: {},
  isDeletable: false
};

export default Vue.extend({
  name: "ClientsTable",
  components: {
    PortfolioTypeChip,
    EmptyCard,
    Modal,
    ButtonCircle,
    ButtonToqui,
    RequireConfirmation,
    ContextMenu,
    ContextMenuOption
  },
  props: {
    critical: {
      type: Boolean,
      default: undefined
    }
  },
  data(): ClientsTableData {
    return {
      totalClients: 0,
      clients: [],
      loading: true,
      options: initialOptions,
      search: undefined,
      portfolioType: undefined,
      error: null,
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "Instalación Principal",
          align: "start",
          sortable: false,
          value: "facilities"
        },
        {
          text: "Nexo Principal",
          align: "start",
          sortable: false,
          value: "nexuses"
        },
        {
          text: "Carteras",
          align: "start",
          sortable: false,
          value: "portfolios",
          width: "50%"
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: "actions"
        }
      ],
      portfolios: [],
      portfoliosForFilter: [],
      portfolioId: 0,
      showPortfoliosModal: false,
      selectedClient: { ...defaultClient },
      selectedPortfolioIds: [],
      switchingPortfolios: false,
      confirmedClientId: 0,
      showConfirmationModal: false,
      rejectedClientId: 0,
      showRejectionModal: false,
      processing: false,
      clientName: "",
      counts: {
        Development: 0,
        Maintenance: 0,
        Activation: 0,
        Critical: 0
      },
      isCritical: this.critical ? true : undefined,
      exporting: false,
      showNexusModal: false,
      nexuses: [],
      selectedNexusId: 0,
      isScheduled: false,
      showDeleteModal: false,
      deleting: false,
      deletedClient: { ...defaultClient }
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi(false);
      },
      deep: true
    }
    // critical() {
    //   this.isCritical = this.critical;
    //   this.getDataFromApi();
    // }
  },
  mounted() {
    this.getDataFromApi();
    this.getPortfolios();
  },
  methods: {
    authorizeRoles: (roles: RoleName[]): boolean => authorizeRoles(roles),
    getDataFromApi(resetPage = true) {
      if (resetPage) this.options.page = 1;
      this.loading = true;
      this.error = null;
      const params: ClientsQuery = {
        page: this.options.page,
        resultsPerPage: this.options.itemsPerPage,
        portfolioType: this.portfolioType,
        text: this.search,
        portfolioId: this.portfolioId === 0 ? undefined : this.portfolioId,
        isCritical: this.isCritical
      };
      getClients(params)
        .then((response: AxiosResponse<PaginatedResult<Client>>) => {
          this.clients = response.data.data.map((x: Client) => {
            return {
              ...x,
              mainFacility: this.getMainFacility(x),
              mainNexus: this.getMainNexus(x)
            };
          });
          this.totalClients = response.data.totalCount;
          this.counts = response.data.counts;
          this.loading = false;
        })
        .catch(() => {
          this.clients = [];
          this.totalClients = 0;
          this.loading = false;
          this.error = "Ha habido un error consultando los datos solicitados.";
        });
    },
    downloadExcel() {
      this.exporting = true;
      const params: ClientsQuery = {
        page: 1,
        resultsPerPage: 100000,
        portfolioType: this.portfolioType,
        text: this.search,
        portfolioId: this.portfolioId === 0 ? undefined : this.portfolioId,
        isCritical: this.isCritical
      };
      getClientsExcel(params).finally(() => {
        this.exporting = false;
      });
    },
    getPortfolios() {
      getPortfolios().then((response: AxiosResponse<Portfolio[]>) => {
        // This map is to create a separate array than the used to filter this view by portfolio.
        this.portfolios = response.data;
        this.portfoliosForFilter = response.data;
        this.portfoliosForFilter.unshift({
          id: 0,
          name: "Todas las carteras",
          clientsCount: 0
        });
      });
    },
    filterBy(type: PortfolioType | undefined = undefined) {
      this.portfolioType = type;
      this.isCritical = undefined;
      this.getDataFromApi();
    },
    filterByCritical() {
      this.portfolioType = undefined;
      this.isCritical = true;
      this.getDataFromApi();
    },
    getMainFacility(client: Client) {
      for (let i = 0; i < client.facilities.length; i++) {
        const facility = client.facilities[i];
        if (facility.isMain) return facility;
      }
    },
    getMainNexus(client: Client) {
      for (let i = 0; i < client.facilities.length; i++) {
        const facility = client.facilities[i];
        for (let j = 0; j < facility.nexuses.length; j++) {
          const nexus = facility.nexuses[j];
          if (nexus.isMain) return nexus;
        }
      }
    },
    startSwitchingPortfolios(client: Client) {
      this.showPortfoliosModal = true;
      this.selectedClient = { ...client };
      this.selectedPortfolioIds = [];
      for (let o = 0; o < client.portfolios.length; o++) {
        const p = client.portfolios[o];
        this.selectedPortfolioIds.push(p.id);
      }
    },
    switchPortfolios() {
      this.switchingPortfolios = true;
      addClientToPortfolios(this.selectedClient.id, this.selectedPortfolioIds)
        .then(() => {
          this.getDataFromApi();
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: "Cliente asignado a carteras exitosamente.",
            color: SnackbarColor.success
          });
        })
        .catch(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text:
              "Ha habido un error reasignando el cliente a las carteras seleccionadas.",
            color: SnackbarColor.error
          });
        })
        .finally(() => {
          this.closeSwitchingPortfoliosModal();
        });
    },
    closeSwitchingPortfoliosModal() {
      this.showPortfoliosModal = false;
      this.selectedClient = { ...defaultClient };
      this.selectedPortfolioIds = [];
      this.switchingPortfolios = false;
    },
    startConfirmation(client: Client) {
      this.showConfirmationModal = true;
      this.confirmedClientId = client.id;
      this.clientName = client.name;
    },
    confirm() {
      this.processing = true;
      confirmClient(this.confirmedClientId)
        .then(() => {
          this.getDataFromApi();
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `Cliente confirmado exitosamente.`,
            color: SnackbarColor.success
          });
        })
        .catch(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `Ha habido un problema confirmando al cliente.`,
            color: SnackbarColor.error
          });
        })
        .finally(() => {
          this.cancelConfirmation();
          this.processing = false;
        });
    },
    cancelConfirmation() {
      this.showConfirmationModal = false;
      this.confirmedClientId = 0;
    },
    startRejection(client: Client) {
      this.showRejectionModal = true;
      this.rejectedClientId = client.id;
      this.clientName = client.name;
    },
    reject() {
      this.processing = true;
      rejectClient(this.rejectedClientId)
        .then(() => {
          this.getDataFromApi();
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `Cliente rechazado exitosamente.`,
            color: SnackbarColor.success
          });
        })
        .catch(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `Ha habido un problema rechazando al cliente.`,
            color: SnackbarColor.error
          });
        })
        .finally(() => {
          this.cancelRejection();
        });
    },
    cancelRejection() {
      this.showRejectionModal = false;
      this.rejectedClientId = 0;
    },
    startSchedulingContact(client: Client, isScheduled: boolean) {
      this.isScheduled = isScheduled;
      this.showNexusModal = true;
      getClientNexuses(client).then((response: AxiosResponse<Nexus[]>) => {
        this.nexuses = response.data;
      });
      // TODO: handle failures here.
    },
    scheduleOrInformContact() {
      if (this.isScheduled) {
        this.$store.commit(MutationTypes.SHOW_SCHEDULE_CONTACT_FORM, {
          nexusId: this.selectedNexusId
        });
      } else {
        this.$store.commit(MutationTypes.SHOW_INFORM_UNSCHEDULED_CONTACT_FORM, {
          nexusId: this.selectedNexusId
        });
      }
      this.showNexusModal = false;
    },
    startDeletion(client: Client) {
      this.deletedClient = { ...client };
      this.showDeleteModal = true;
    },
    cancelDeletion() {
      this.showDeleteModal = false;
      this.deletedClient = { ...defaultClient };
    },
    deleteClient() {
      this.deleting = true;
      deleteClient(this.deletedClient)
        .then(() => {
          this.getDataFromApi();
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `Cliente ${this.deletedClient.name} eliminado exitosamente.`,
            color: SnackbarColor.success
          });
        })
        .finally(() => {
          this.showDeleteModal = false;
          this.deletedClient = { ...defaultClient };
          this.deleting = false;
        });
    }
  },
  computed: {
    allFiltered(): boolean {
      return this.portfolioType === undefined && !this.isCritical;
    },
    maintenanceFiltered(): boolean {
      return this.portfolioType == PortfolioType.Maintenance;
    },
    developmentFiltered(): boolean {
      return this.portfolioType == PortfolioType.Development;
    },
    activationFiltered(): boolean {
      return this.portfolioType == PortfolioType.Activation;
    },
    PortfolioType: () => PortfolioType,
    Kind: () => Kind,
    ToquiKind: () => ToquiKind,
    RoleName: () => RoleName
  }
});
