
import BaseScheduledContactForm from "@/components/contacts/BaseScheduledContactForm.vue";
import InformContactForm from "@/components/contacts/InformContactForm.vue";
import ButtonCircle, { Kind } from "@/components/ButtonCircle.vue";
import { deleteContact, editContact, informScheduledContact } from "@/api";
import { Contact, SnackbarColor } from "@/models";
import Vue from "vue";
import { MutationTypes } from "@/store";
import { AxiosError } from "axios";
import dayjs from "dayjs";
export default Vue.extend({
  components: {
    BaseScheduledContactForm,
    InformContactForm,
    ButtonCircle
  },
  data() {
    return {
      informing: false,
      deleting: false,
      editing: false,
      processing: false,
      contactData: {
        nexusId: this.selectedContact.nexus.id,
        dateTime: this.selectedContact.dateTime,
        contactTypeId: this.selectedContact.contactType.id,
        objectiveId: this.selectedContact.objective.id,
        agreements: this.selectedContact.agreements,
        nextActivity: this.selectedContact.nextActivity,
        portfolioId: this.selectedContact.portfolio.id,
        observations: this.selectedContact.observations
      },
      errors: {}
    };
  },
  props: {
    selectedContact: Object as () => Contact
  },
  methods: {
    currentDate() {
      return dayjs();
    },
    deleteContact() {
      this.deleting = false;
      this.processing = true;
      deleteContact(this.selectedContact)
        .then(() => {
          this.$emit("deleted");
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: "Contacto eliminado exitosamente.",
            color: SnackbarColor.success
          });
          this.deleting = false;
        })
        .finally(() => {
          this.processing = false;
        });
    },
    editContact() {
      this.processing = true;
      editContact(this.selectedContact, this.contactData)
        .then(() => {
          this.$emit("updated");
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: "Contacto editado exitosamente.",
            color: SnackbarColor.success
          });
          this.editing = false;
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.errors = { ...error.response.data.errors };
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
    informContact() {
      this.processing = true;
      informScheduledContact(this.selectedContact, this.contactData)
        .then(() => {
          this.$emit("updated");
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: "Contacto informado exitosamente.",
            color: SnackbarColor.success
          });
          this.informing = false;
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.errors = { ...error.response.data.errors };
          }
        })
        .finally(() => {
          this.processing = false;
        });
    }
  },
  computed: {
    Kind: () => Kind
  },
  watch: {
    selectedContact() {
      this.editing = false;
      this.deleting = false;
      this.contactData = {
        nexusId: this.selectedContact.nexus.id,
        dateTime: this.selectedContact.dateTime,
        contactTypeId: this.selectedContact.contactType.id,
        objectiveId: this.selectedContact.objective.id,
        agreements: this.selectedContact.agreements,
        nextActivity: this.selectedContact.nextActivity,
        portfolioId: this.selectedContact.portfolio.id,
        observations: this.selectedContact.observations
      };
    }
  }
});
