
import { changePassword, uploadProfileImage } from "@/api";
import ButtonToqui, { Kind as ToquiKind } from "@/components/ButtonToqui.vue";
import { ChangePasswordData, SnackbarColor, ValidationErrors } from "@/models";
import Modal from "@/components/Modal.vue";
import { MutationTypes } from "@/store";
import { AxiosError, AxiosResponse } from "axios";
import Vue from "vue";
import ButtonCircle, { Kind } from "@/components/ButtonCircle.vue";

interface AccountData {
  imageUrl: string;
  image: File | null;

  // Change password
  changePassword: ChangePasswordData;
  showCurrentPassword: boolean;
  showNewPassword: boolean;
  showConfirmNewPassword: boolean;
  errors: ValidationErrors;

  // Change profile picture.
  showProfilePictureModal: boolean;
  uploadingProfilePicture: boolean;

  // Remove profile picture.
  showRemoveProfilePictureModal: boolean;
  removingProfilePicture: boolean;
}

const defaultChangePassword: ChangePasswordData = {
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: ""
};

export default Vue.extend({
  components: {
    ButtonToqui,
    Modal,
    ButtonCircle
  },
  data(): AccountData {
    return {
      imageUrl: "",
      image: null,
      changePassword: { ...defaultChangePassword },
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmNewPassword: false,
      errors: {},
      showProfilePictureModal: false,
      uploadingProfilePicture: false,
      showRemoveProfilePictureModal: false,
      removingProfilePicture: false
    };
  },
  computed: {
    ToquiKind: () => ToquiKind,
    Kind: () => Kind,
  },
  methods: {
    previewImage() {
      this.imageUrl = URL.createObjectURL(this.image);
    },
    uploadImage() {
      this.uploadingProfilePicture = true;
      if (this.image) {
        uploadProfileImage(this.image)
          .then((response: AxiosResponse<string>) => {
            this.$store.commit(MutationTypes.SET_PROFILE_IMAGE, response.data);
            this.$store.commit(MutationTypes.SHOW_SNACK, {
              text: "La foto de perfil ha sido modificada exitosamente.",
              color: SnackbarColor.success
            });
          })
          .catch(() => {
            this.$store.commit(MutationTypes.SHOW_SNACK, {
              text: "Ha habido un error modificando su foto de perfil.",
              color: SnackbarColor.error
            });
          })
          .finally(() => {
            this.uploadingProfilePicture = false;
            this.showProfilePictureModal = false;
          });
      }
    },
    removeImage() {
      this.removingProfilePicture = true;
      uploadProfileImage()
        .then(() => {
          this.$store.commit(MutationTypes.SET_PROFILE_IMAGE, '');
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: "La foto de perfil ha sido eliminada exitosamente.",
            color: SnackbarColor.success
          });
        })
        .catch(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: "Ha habido un error eliminando su foto de perfil.",
            color: SnackbarColor.error
          });
        })
        .finally(() => {
          this.removingProfilePicture = false;
          this.showRemoveProfilePictureModal = false;
        });
    },
    updatePassword() {
      changePassword(this.changePassword)
        .then(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: "Contraseña actualizada exitosamente.",
            color: SnackbarColor.success
          });
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.errors = error.response.data.errors;
          } else {
            this.$store.commit(MutationTypes.SHOW_SNACK, {
              text: "Ha habido un problema actualizando su contraseña.",
              color: SnackbarColor.error
            });
          }
          this.errors = {};
        })
        .finally(() => {
          this.changePassword = { ...defaultChangePassword };
        });
    }
  }
});
