
import { getContacts } from "@/api";
import { Contact, ContactKind, contactKindName, ContactsQuery } from "@/models";
import { AxiosResponse } from "axios";
import Modal from "@/components/Modal.vue";
import ContactDetails from "@/components/contacts/ContactDetails.vue";
import Vue from "vue";
import dayjs, { Dayjs } from "dayjs";
import { CalendarTimestamp } from "vuetify";
import { mapState } from "vuex";

interface CalendarEvent {
  name: string;
  start: string;
  end: string;
  contact: Contact;
  color: string;
}

interface CalendarType {
  value: string;
  name: string;
}

interface ContactsCalendarData {
  events: CalendarEvent[];
  displayContact: boolean;
  informContact: boolean;
  selectedContact: Contact | null;
  types: CalendarType[];
  type: CalendarType;
  focus: string;
  loading: boolean;
  show: boolean;
  date: Dayjs;
  monthNames: string[];
  start: string;
}

export default Vue.extend({
  components: {
    Modal,
    ContactDetails
  },
  computed: {
    largeViewport(): boolean {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
        case "md":
          return false;
        case "lg":
        case "xl":
        default:
          return true;
      }
    },
    ...mapState(["displayDayCalendar"])
  },
  watch: {
    displayDayCalendar() {
      this.type = { name: "Día", value: "day" };
      this.showCalendar();
    }
  },
  data(): ContactsCalendarData {
    return {
      events: [],
      displayContact: false,
      informContact: false,
      selectedContact: null,
      types: [
        { name: "Mes", value: "month" },
        { name: "Semana", value: "week" },
        { name: "Día", value: "day" }
      ],
      type: { name: "Semana", value: "week" },
      focus: "",
      loading: false,
      show: false,
      date: dayjs(),
      monthNames: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      ],
      start: dayjs().format("YYYY-MM-DD")
    };
  },
  // updated() {
  //     this.calendarInstance().scrollToTime("08:00");
  // },
  methods: {
    contactKindName,
    calendarInstance(): Vue & {
      scrollToTime(time: string): () => boolean;
      prev(): () => void;
      next(): () => void;
      lastStart: CalendarTimestamp | null;
      lastEnd: CalendarTimestamp | null;
    } {
      return this.$refs.calendar as Vue & {
        scrollToTime(time: string): () => boolean;
        prev(): () => void;
        next(): () => void;
        lastStart: CalendarTimestamp | null;
        lastEnd: CalendarTimestamp | null;
      };
    },
    // eslint-disable-next-line
    showContact(detail: any) {
      this.selectedContact = detail.event.contact;
      this.displayContact = true;
    },
    contactColor(contact: Contact) {
      switch (contact.contactType.kind) {
        case ContactKind.Cold:
          return "info";
        case ContactKind.Scheduled:
          if(contact.aborted){
            return "accent";
          }
          return "warning";
        case ContactKind.Inertia:
          return "primary";
        default:
          return "primary";
      }
    },
    // eslint-disable-next-line
    getEvents({ start, end }: { start: any; end: any }) {
      this.loading = true;
      const startDate = dayjs(start.date).toISOString();
      const endDate = dayjs(end.date)
        .add(1, "days")
        .toISOString();

      this.date = dayjs(start.date);
      const contactsQuery: ContactsQuery = {
        startDate: startDate,
        endDate: endDate
      };
      getContacts(contactsQuery)
        .then((response: AxiosResponse<Contact[]>) => {
          const contacts = response.data;
          this.events = contacts.map((x: Contact) => {
            let event: CalendarEvent = {
              name: x.client.name,
              start: x.dateTime.format("YYYY-MM-DD HH:mm:ss"),
              end: x.dateTime.add(1, "hour").format("YYYY-MM-DD HH:mm:ss"),
              contact: x,
              color: this.contactColor(x)
            };
            return event;
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goToDay(event: { date: string }) {
      this.type = { name: "Día", value: "day" };
      this.start = event.date;
    },
    prev() {
      this.calendarInstance().prev();
    },
    next() {
      this.calendarInstance().next();
    },
    showCalendar() {
      this.show = !this.show;
      if (this.show) {
        const instance = this.calendarInstance();
        if (instance) {
          this.getEvents({ start: instance.lastStart, end: instance.lastEnd });
        }
      }
    },
    reloadEvents() {
      this.displayContact = false;
      const instance = this.calendarInstance();
      this.getEvents({ start: instance.lastStart, end: instance.lastEnd });
    }
  }
});
