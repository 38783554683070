
import { RoleName } from '@/models';
import store from '@/store';

export const rules: { [key: string]: (value: string) => (boolean | string) } = {
    required: (value: string) : (boolean | string) => !!value || 'Este campo es requerido.',
    onlyNumbers: (value: string) : (boolean | string) => /[0-9]/.test(value) || 'Este campo solo admite números.',
    numbersOrEmpty: (value: string) : (boolean | string) => /(^[0-9]+$|^$)/.test(value) || 'Este campo solo admite números.',
    time: (value: string) : (boolean | string) => /(^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$)/.test(value) || 'Ingrese una hora válida.'
}

// Para mostrar o esconder elementos visuales en función de la autorización.
export const authorizeRoles = function(roles: RoleName[]) : boolean {
    // SuperAdministrators can see everything.
    if (store.state.user?.roles && store.state.user.roles.includes(RoleName.SuperAdministrator)) return true;
    for (let i = 0; i < roles.length; i++) {
        if (store.state.user?.roles?.includes(roles[i]))
        {
            return true;
        }
    }
    return false;
}


