
import Vue from "vue";

export enum Kind {
  accent,
  cancel
}

export default Vue.extend({
  props: {
    kind: Number as () => Kind,
    disabled: Boolean,
    icon: String,
    to: String,
    text: String,
    outlined: Boolean
  },
  computed: {
    Kind: () => Kind,
    color() {
      switch (this.kind) {
        case Kind.accent:
          return "accent";
        case Kind.cancel:
          return "primary";
        default:
          return "primary";
      }
    },
    textType(): boolean {
      switch (this.kind) {
        case Kind.cancel:
          return true;
        default:
          return false;
      }
    }
  },
  methods: {
    click() {
      this.$emit("click");
    }
  }
});
