
import { getClients, getPortfolios, getProductsGroups } from "@/api";
import {
  Client,
  ClientsQuery,
  PaginatedResult,
  Portfolio,
  ProductsGroup,
  QuoteData,
  ValidationErrors
} from "@/models";
import { rules } from "@/helpers";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import NumberInput from "@/components/NumberInput.vue";
import Vue from "vue";

interface QuoteFormData {
  date: string;
  isSalesperson: boolean;
  portfolios: Portfolio[];
  clients: Client[];
  dateMenu: boolean;
  initialPortfolioId: number;
  rules: { [key: string]: (value: string) => boolean | string };
  productsGroups: ProductsGroup[];
}

export default Vue.extend({
  components: {
    NumberInput
  },
  props: {
    quote: {
      type: Object as () => QuoteData
    },
    errors: {
      type: Object as () => ValidationErrors
    }
  },
  data(): QuoteFormData {
    return {
      date: this.quote.date.format("YYYY-MM-DD"),
      isSalesperson: this.$store.state.user.isSalesperson,
      portfolios: [],
      clients: [],
      dateMenu: false,
      initialPortfolioId: this.quote.portfolioId,
      rules,
      productsGroups: []
    };
  },
  computed: {
    dateFormatted(): string {
      return this.quote.date.format("DD-MM-YYYY");
    },
    // This is just to watch chantes on portfolioId and load related clients.
    portfolioId(): number {
      return this.quote.portfolioId;
    }
  },
  watch: {
    date: function(): void {
      this.quote.date = dayjs(`${this.date}T00:00:00`);
    },
    portfolioId: function(): void {
      this.getClients();
    }
  },
  mounted() {
    this.getPortfolios();
    this.getProductsGroups();
  },
  methods: {
    getPortfolios() {
      const salespersonId = this.isSalesperson
        ? this.$store.state.user.id
        : null;
      getPortfolios(salespersonId).then(
        (response: AxiosResponse<Portfolio[]>) => {
          this.portfolios = response.data;
          if (this.quote.portfolioId === 0 && this.portfolios.length > 0) {
            this.quote.portfolioId = this.portfolios[0].id;
          }
          this.getClients();
        }
      );
      // TODO: handle failures.
    },
    getProductsGroups() {
      getProductsGroups().then((response: AxiosResponse<ProductsGroup[]>) => {
        this.productsGroups = response.data;
        if (
          this.quote.productsGroupId === 0 &&
          this.productsGroups.length > 0
        ) {
          this.quote.productsGroupId = this.productsGroups[0].id;
        }
      });
      // TODO: handle failures.
    },
    getClients() {
      const params: ClientsQuery = {
        page: 1,
        resultsPerPage: 10000,
        portfolioType: undefined,
        text: undefined,
        portfolioId: this.quote.portfolioId,
        confirmed: true
      };
      getClients(params).then(
        (response: AxiosResponse<PaginatedResult<Client>>) => {
          this.clients = response.data.data;
          if (
            this.initialPortfolioId !== this.quote.portfolioId &&
            this.clients.length > 0
          ) {
            this.quote.clientId = this.clients[0].id;
          }
        }
      );
      // TODO: handle failures here.
    }
  }
});
