
import { PortfolioType } from "@/models";
import Vue from "vue";
export default Vue.extend({
  props: {
    kind: {
      type: String as () => PortfolioType
    }
  },
  computed: {
    PortfolioType: () => PortfolioType,
    color() {
      switch (this.kind) {
        case PortfolioType.Development:
          return "primary";
        case PortfolioType.Maintenance:
          return "secondary";
        case PortfolioType.Activation:
          return "accent";
        default:
          return "primary";
      }
    },
    text() {
      switch (this.kind) {
        case PortfolioType.Development:
          return "Desarrollo";
        case PortfolioType.Maintenance:
          return "Mantención";
        case PortfolioType.Activation:
          return "Activación";
        default:
          return "No definido";
      }
    },
    icon() {
      switch (this.kind) {
        case PortfolioType.Development:
          return "mdi-account-switch-outline";
        case PortfolioType.Maintenance:
          return "mdi-account-convert-outline";
        case PortfolioType.Activation:
          return "mdi-account-reactivate-outline";
        default:
          return "No definido";
      }
    }
  }
});
