
import {
  BaseScheduledContact,
  ContactKind,
  ScheduleContactData,
  SnackbarColor,
  ValidationErrors
} from "@/models";
import BaseScheduledContactForm from "@/components/contacts/BaseScheduledContactForm.vue";
import ButtonToqui, { Kind as ToquiKind } from "@/components/ButtonToqui.vue";
import Modal from "@/components/Modal.vue";
import Vue from "vue";
import { scheduleContact } from "@/api";
import { AxiosError } from "axios";
import { MutationTypes } from "@/store";
import dayjs from "dayjs";
import { mapState } from "vuex";

interface ContactFormData extends BaseScheduledContact {
  notifyClient: boolean;
  agreements: string;
  nextActivity: string;
}

const defaultScheduleContact: ContactFormData = {
  nexusId: 0,
  dateTime: dayjs(),
  contactTypeId: 0,
  objectiveId: 0,
  portfolioId: 0,
  notifyClient: false,
  agreements: "",
  nextActivity: "",
  observations: ""
};

interface ContactData {
  contact: ContactFormData;
  errors: ValidationErrors;
  selectedContactKind: ContactKind;
  saving: boolean;
  errorMessage: string;
}

export default Vue.extend({
  // Mapping the state of the other snacbkar properties.
  components: {
    BaseScheduledContactForm,
    ButtonToqui,
    Modal,
  },
  computed: {
    ToquiKind: () => ToquiKind,
    ContactKind: () => ContactKind,
    ...mapState(["scheduleContactForm"]),
  },
  data(): ContactData {
    return {
      contact: {
        ...defaultScheduleContact
      },
      errors: {},
      selectedContactKind: ContactKind.Scheduled,
      saving: false,
      errorMessage: "",
    };
  },
  methods: {
    close() {
      this.saving = false;
      this.errors = {};
      this.contact.agreements = "";
      this.contact.nextActivity = "";
      this.contact.observations = "";
      this.contact.notifyClient = false;
      this.$store.commit(MutationTypes.HIDE_SCHEDULE_CONTACT_FORM);
    },
    sleep(time: number) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
    saveContact() {
      // Llama a un método diferente dependiendo del tipo de contacto seleccionado.
      this.saving = true;

      let scheduleContactData: ScheduleContactData = {
        nexusId: this.scheduleContactForm.nexusId,
        dateTime: this.contact.dateTime,
        contactTypeId: this.contact.contactTypeId,
        objectiveId: this.contact.objectiveId,
        notifyClient: this.contact.notifyClient,
        portfolioId: this.contact.portfolioId,
        observations: this.contact.observations,
        quoteId: this.scheduleContactForm.quoteId ? this.scheduleContactForm.quoteId : undefined
      };
      scheduleContact(scheduleContactData)
        .then(() => {
          this.close();
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: "Contacto guardado exitosamente.",
            color: SnackbarColor.success
          });
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.errors = error.response.data.errors;
          } else {
            this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: "El contacto no ha podido ser agendado. Revise la información ingresada en el formulario.",
            color: SnackbarColor.error
          });
          }
        })
        .finally(() => {
          this.saving = false;
        });
    }
  }
});
