
import Vue from "vue";
import { Credentials, ValidationErrors } from "@/models";
import store, { ActionTypes } from "@/store";
import { mapState } from "vuex";
import { AxiosError, AxiosResponse } from "axios";
import { getLogo, login } from "@/api";

const credentials: Credentials = {
  username: "",
  password: "",
  useCookies: true
};

export interface LoginState {
  credentials: Credentials;
  show: boolean;
  error: string;
  validationErrors: ValidationErrors;
  logo: string;
}

export default Vue.extend({
  name: "Login",
  props: {
    source: String
  },
  data(): LoginState {
    return {
      credentials,
      show: false,
      error: "",
      validationErrors: {},
      logo: ""
    };
  },
  computed: mapState(["authenticated"]),
  mounted() {
    getLogo().then((response: AxiosResponse<string>) => {
      this.logo = response.data;
    });
  },
  methods: {
    LogIn(): void {
      // Resetting errors.
      this.error = "";
      this.validationErrors = {};
      login(credentials)
        .then(() => {
          this.credentials.password = "";
          store.dispatch(ActionTypes.LOGIN);
        })
        .catch((error: AxiosError) => {
          console.log(error);
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status == 400) {
              this.validationErrors = { ...error.response.data.errors };
            }
            // Unauthorized request.
            if (error.response.status == 409) this.error = error.response.data;
            // Not found.
            if (error.response.status == 404)
              this.error = "Cuenta de usuario no encontrada.";
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    }
  }
});
