
import Vue from "vue";
import {
  Brand,
  BusinessSegment,
  BusinessUnit,
  GeographicZone,
  ProductsGroup,
  ValidationErrors
} from "@/models";
export default Vue.extend({
  props: {
    segment: {
      type: Object as () => BusinessSegment
    },
    brands: {
      type: Array as () => Brand[]
    },
    businessUnits: {
      type: Array as () => BusinessUnit[]
    },
    geographicZones: {
      type: Array as () => GeographicZone[]
    },
    productsGroups: {
      type: Array as () => ProductsGroup[]
    },
    validationErrors: {
      type: Object as () => ValidationErrors
    }
  }
});
