import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store, { ActionTypes } from './store'
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false


store.dispatch(ActionTypes.INITIALIZE)
.then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
});

