import axios, { AxiosResponse } from 'axios';
import store, { MutationTypes } from '@/store';
import { Brand, Client, Nexus, ClientsQuery, Credentials, Facility, PaginatedResult, User, Portfolio, BusinessSegment, GeographicZone, BusinessUnit, ProductsGroup, SystemParameters, SalespersonType, Salesperson, ContactType, Contact, ContactsQuery, ScheduleContactData, ContactData, InformScheduledContactData, InformUnscheduledContactData, Objective, SalespeopleQuery, QuotesQuery, Quote, QuoteData, GapDocumentsQuery, GapDocument, GapDocumentData, PortfolioGoal, SaleData, Sale, SalesQuery, Commune, PortfolioIndicator, Confirmation, DailyReport, PaginatedQuery, GoalAchievments, PortfolioComposition, RoleName, ChangePasswordData, ActivateAccountData, ForgotPasswordData, ResetPasswordData, DailyCoverages, DailyReportPayload, OperativeBalance, QuoteStatus, Sector, CustomTextField, CustomSelectableField, CustomOption, Country, SnackbarColor, CreateClientData } from '@/models';
import dayjs, { Dayjs } from 'dayjs';

// Creates a default instance of axios to be used by this app.
export const backendUrl: string | undefined = process.env.NODE_ENV == 'development' ? `http://localhost:6655` : `${window.location.origin}`;
export const apiUrl: string | undefined = process.env.NODE_ENV == 'development' ? `http://localhost:6655/api` : `${window.location.origin}/api`;

const axiosInstance = axios.create({
    baseURL: apiUrl,
    withCredentials: true
});

// Datetime parsing (accepts ISO string)
// This is based on https://stackoverflow.com/questions/65692061/casting-dates-properly-from-an-api-response-in-typescript.
const isoDateFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;

// eslint-disable-next-line
function isIsoDateString(value: any): boolean {
    return value && typeof value === "string" && isoDateFormat.test(value);
}

// eslint-disable-next-line
export function handleDates(body: any) {
    if (body === null || body === undefined || typeof body !== "object")
        return body;

    for (const key of Object.keys(body)) {
        const value = body[key];
        if (isIsoDateString(value)) body[key] = dayjs(value);
        else if (typeof value === "object") handleDates(value);
    }
}

axiosInstance.interceptors.response.use(originalResponse => {
    handleDates(originalResponse.data);
    return originalResponse;
});
// Datetime parsing end.

// Intercepting 401 errors and logging out user.
axiosInstance.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status == 401 && store.state.authenticated) {
        store.commit(MutationTypes.SHOW_SNACK, {
            text: "No está autorizado para realizar esta acción.",
            color: SnackbarColor.error
        });
    }
    else
        if (error.response.status == 409) {
            store.commit(MutationTypes.SHOW_SNACK, {
                text: error.response.data,
                color: SnackbarColor.warning
            });
        }
    return Promise.reject(error);
});

export default axiosInstance;

// Account
export const login = function (credentials: Credentials): Promise<AxiosResponse> {
    return axiosInstance.post<Credentials>('Account/Login', credentials, { withCredentials: true });
}

export const logout = function (): Promise<AxiosResponse> {
    return axiosInstance.post<unknown, AxiosResponse>('Account/Logout');
}

export const getCurrentUser = function (): Promise<AxiosResponse<User>> {
    return axiosInstance.get<unknown, AxiosResponse<User>>('Account/GetUser');
}

export const uploadProfileImage = function (file: File | undefined = undefined): Promise<AxiosResponse<string>> {
    if (file) {
        const fd = new FormData();
        fd.append('file', file);
        return axiosInstance.put<File, AxiosResponse<string>>('Account/ProfileImage', fd, { headers: { 'Content-Type': 'multipart/form-data' } });
    }
    return axiosInstance.put<File, AxiosResponse<string>>('Account/ProfileImage');
}

export const forgotPassword = function (forgotPasswordData: ForgotPasswordData): Promise<AxiosResponse> {
    return axiosInstance.post<ForgotPasswordData, AxiosResponse>('Account/ForgotPassword', forgotPasswordData);
}

export const resetPassword = function (resetPasswordData: ResetPasswordData): Promise<AxiosResponse> {
    return axiosInstance.post<ResetPasswordData, AxiosResponse>('Account/ResetPassword', resetPasswordData);
}

export const changePassword = function (changePasswordData: ChangePasswordData): Promise<AxiosResponse> {
    return axiosInstance.post<ChangePasswordData, AxiosResponse>('Account/ChangePassword', changePasswordData);
}

export const activateAccount = function (activateAccountData: ActivateAccountData): Promise<AxiosResponse<string>> {
    return axiosInstance.post<ActivateAccountData, AxiosResponse<string>>('Account/ActivateAccount', activateAccountData);
}

// Brands

export const getBrands = function (): Promise<AxiosResponse<Brand[]>> {
    return axiosInstance.get<unknown, AxiosResponse<Brand[]>>('Brands');
}

export const createBrand = function (brand: Brand): Promise<AxiosResponse<Brand>> {
    return axiosInstance.post<Brand, AxiosResponse<Brand>>('Brands', brand);
}

export const editBrand = function (brand: Brand): Promise<AxiosResponse> {
    return axiosInstance.put<unknown, AxiosResponse>(`Brands/${brand.id}`, brand);
}

export const deleteBrand = function (brand: Brand): Promise<AxiosResponse> {
    return axiosInstance.delete<unknown, AxiosResponse>(`Brands/${brand.id}`);
}

// GeographicZones

export const getGeographicZones = function (): Promise<AxiosResponse<GeographicZone[]>> {
    return axiosInstance.get<unknown, AxiosResponse<GeographicZone[]>>('GeographicZones');
}

export const createGeographicZone = function (geographicZone: GeographicZone): Promise<AxiosResponse<GeographicZone>> {
    return axiosInstance.post<GeographicZone, AxiosResponse<GeographicZone>>('GeographicZones', geographicZone);
}

export const editGeographicZone = function (geographicZone: GeographicZone): Promise<AxiosResponse> {
    return axiosInstance.put<unknown, AxiosResponse>(`GeographicZones/${geographicZone.id}`, geographicZone);
}

export const deleteGeographicZone = function (geographicZone: GeographicZone): Promise<AxiosResponse> {
    return axiosInstance.delete<unknown, AxiosResponse>(`GeographicZones/${geographicZone.id}`);
}

// Nexuses
export const getNexus = function (id: number): Promise<AxiosResponse<Nexus>> {
    return axiosInstance.get<unknown, AxiosResponse<Nexus>>(`Nexuses/${id}`);
}

export const editNexus = function (nexus: Nexus): Promise<AxiosResponse> {
    return axiosInstance.put<Nexus, AxiosResponse>(`Nexuses/${nexus.id}`, nexus);
}

export const deleteNexus = function (nexus: Nexus): Promise<AxiosResponse> {
    return axiosInstance.delete<unknown, AxiosResponse>(`Nexuses/${nexus.id}`);
}

export const setNexusActivationStatus = function (nexus: Nexus, isActive: boolean): Promise<AxiosResponse> {
    return axiosInstance.put<boolean, AxiosResponse>(`Nexuses/${nexus.id}/ActivationStatus/${isActive}`);
}

export const setMainNexus = function (nexusId: number): Promise<AxiosResponse<Nexus>> {
    return axiosInstance.put<unknown, AxiosResponse<Nexus>>(`Nexuses/${nexusId}/MainStatus`);
}

export const confirmNexus = function (nexusId: number): Promise<AxiosResponse> {
    return axiosInstance.post<unknown, AxiosResponse>(`Nexuses/${nexusId}/Confirm`);
}

export const rejectNexus = function (nexusId: number): Promise<AxiosResponse> {
    return axiosInstance.post<unknown, AxiosResponse>(`Nexuses/${nexusId}/Reject`);
}

export const validateNexus = function (nexus: Nexus): Promise<AxiosResponse> {
    return axiosInstance.post<Nexus, AxiosResponse>('Nexuses/Validate', nexus);
}


// BusinessUnits

export const getBusinessUnits = function (): Promise<AxiosResponse<BusinessUnit[]>> {
    return axiosInstance.get<unknown, AxiosResponse<BusinessUnit[]>>('BusinessUnits');
}

export const createBusinessUnit = function (BusinessUnit: BusinessUnit): Promise<AxiosResponse<BusinessUnit>> {
    return axiosInstance.post<BusinessUnit, AxiosResponse<BusinessUnit>>('BusinessUnits', BusinessUnit);
}

export const editBusinessUnit = function (BusinessUnit: BusinessUnit): Promise<AxiosResponse> {
    return axiosInstance.put<unknown, AxiosResponse>(`BusinessUnits/${BusinessUnit.id}`, BusinessUnit);
}

export const deleteBusinessUnit = function (BusinessUnit: BusinessUnit): Promise<AxiosResponse> {
    return axiosInstance.delete<unknown, AxiosResponse>(`BusinessUnits/${BusinessUnit.id}`);
}

// ProductsGroups

export const getProductsGroups = function (): Promise<AxiosResponse<ProductsGroup[]>> {
    return axiosInstance.get<unknown, AxiosResponse<ProductsGroup[]>>('ProductsGroups');
}

export const createProductsGroup = function (productsGroup: ProductsGroup): Promise<AxiosResponse<ProductsGroup>> {
    return axiosInstance.post<ProductsGroup, AxiosResponse<ProductsGroup>>('ProductsGroups', productsGroup);
}

export const editProductsGroup = function (productsGroup: ProductsGroup): Promise<AxiosResponse> {
    return axiosInstance.put<unknown, AxiosResponse>(`ProductsGroups/${productsGroup.id}`, productsGroup);
}

export const deleteProductsGroup = function (productsGroup: ProductsGroup): Promise<AxiosResponse> {
    return axiosInstance.delete<unknown, AxiosResponse>(`ProductsGroups/${productsGroup.id}`);
}

// Clients
export const getClients = function (params: ClientsQuery): Promise<AxiosResponse<PaginatedResult<Client>>> {
    return axiosInstance.get<ClientsQuery, AxiosResponse<PaginatedResult<Client>>>('Clients', { params });
}

export const getClientsExcel = function (params: ClientsQuery): Promise<void> {
    return axiosInstance.get<unknown, AxiosResponse<Blob>>(`Clients/Excel`, { params, responseType: 'blob' })
        .then((response: AxiosResponse<Blob>) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Base de Clientes ${dayjs().format('DD-MM-YYYY')}.xlsx`);
            document.body.appendChild(link);
            link.click();
        });
}

export const getClientNexuses = function (client: Client): Promise<AxiosResponse<Nexus[]>> {
    return axiosInstance.get<ClientsQuery, AxiosResponse<Nexus[]>>(`Clients/${client.id}/Nexuses`);
}

export const getClient = function (id: number): Promise<AxiosResponse<Client>> {
    return axiosInstance.get<unknown, AxiosResponse<Client>>(`Clients/${id}`);
}

export const createClient = function (client: Client): Promise<AxiosResponse<Client>> {
    return axiosInstance.post<Client, AxiosResponse<Client>>('Clients', client);
}

export const editClient = function (client: Client): Promise<AxiosResponse> {
    return axiosInstance.put<Client, AxiosResponse>(`Clients/${client.id}`, client);
}

export const deleteClient = function (client: Client): Promise<AxiosResponse> {
    return axiosInstance.delete<unknown, AxiosResponse>(`Clients/${client.id}`);
}

export const createFacility = function (clientId: number, facility: Facility): Promise<AxiosResponse<Facility>> {
    return axiosInstance.post<Facility, AxiosResponse<Facility>>(`Clients/${clientId}/Facilities`, facility);
}

export const addClientToPortfolios = function (clientId: number, portfolioIds: number[]): Promise<AxiosResponse> {
    return axiosInstance.put<number[], AxiosResponse>(`Clients/${clientId}/Portfolios`, portfolioIds);
}

export const confirmClient = function (clientId: number): Promise<AxiosResponse> {
    return axiosInstance.post<unknown, AxiosResponse>(`Clients/${clientId}/Confirm`);
}

export const rejectClient = function (clientId: number): Promise<AxiosResponse> {
    return axiosInstance.post<unknown, AxiosResponse>(`Clients/${clientId}/Reject`);
}

export const getLastContact = function (clientId: number): Promise<AxiosResponse<Contact>> {
    return axiosInstance.get<unknown, AxiosResponse<Contact>>(`Clients/${clientId}/LastContact`);
}

export const validateClient = function (client: CreateClientData): Promise<AxiosResponse> {
    return axiosInstance.post<CreateClientData, AxiosResponse>('Clients/Validate', client);
}

// Facilities
export const editFacility = function (facility: Facility): Promise<AxiosResponse> {
    return axiosInstance.put<Facility, AxiosResponse>(`Facilities/${facility.id}`, facility);
}

export const deleteFacility = function (facility: Facility): Promise<AxiosResponse> {
    return axiosInstance.delete<unknown, AxiosResponse>(`Facilities/${facility.id}`);
}

export const createNexus = function (facilityId: number, nexus: Nexus): Promise<AxiosResponse<Nexus>> {
    return axiosInstance.post<Nexus, AxiosResponse<Nexus>>(`Facilities/${facilityId}/Nexuses`, nexus);
}

export const moveNexus = function (facilityId: number, nexus: Nexus): Promise<AxiosResponse<Nexus>> {
    return axiosInstance.put<unknown, AxiosResponse<Nexus>>(`Facilities/${facilityId}/Nexuses/${nexus.id}`);
}

export const setMainFacility = function (facilityId: number): Promise<AxiosResponse<Nexus>> {
    return axiosInstance.put<unknown, AxiosResponse<Nexus>>(`Facilities/${facilityId}/MainStatus`);
}

export const confirmFacility = function (facilityId: number): Promise<AxiosResponse> {
    return axiosInstance.post<unknown, AxiosResponse>(`Facilities/${facilityId}/Confirm`);
}

export const rejectFacility = function (facilityId: number): Promise<AxiosResponse> {
    return axiosInstance.post<unknown, AxiosResponse>(`Facilities/${facilityId}/Reject`);
}

export const validateFacility = function (facility: Facility): Promise<AxiosResponse> {
    return axiosInstance.post<Facility, AxiosResponse>('Facilities/Validate', facility);
}

// Portfolios
export const getPortfolios = function (
    salespersonId: number | null = null,
    businessSegmentId: number | null = null,
    clientId: number | null = null
): Promise<AxiosResponse<Portfolio[]>> {
    const params: { [key: string]: number } = {};
    if (salespersonId) params.salespersonId = salespersonId;
    if (businessSegmentId) params.businessSegmentId = businessSegmentId;
    if (clientId) params.clientId = clientId;
    return axiosInstance.get<unknown, AxiosResponse<Portfolio[]>>('Portfolios', { params });
}

export const getPortfolio = function (id: number): Promise<AxiosResponse<Portfolio>> {
    return axiosInstance.get<unknown, AxiosResponse<Portfolio>>(`Portfolios/${id}`);
}

export const editPortfolio = function (portfolio: Portfolio): Promise<AxiosResponse> {
    return axiosInstance.put<Portfolio, AxiosResponse>(`Portfolios/${portfolio.id}`, portfolio);
}

export const deletePortfolio = function (portfolio: Portfolio): Promise<AxiosResponse> {
    return axiosInstance.delete<unknown, AxiosResponse>(`Portfolios/${portfolio.id}`);
}

export const addClientsToPortfolio = function (portfolioId: number, clientIds: number[]): Promise<AxiosResponse> {
    return axiosInstance.post<number[], AxiosResponse>(`Portfolios/${portfolioId}`, clientIds);
}

export const removeClientsFromPortfolio = function (portfolioId: number, clientIds: number[]): Promise<AxiosResponse> {
    return axiosInstance.delete<number[], AxiosResponse>(`Portfolios/${portfolioId}`, { data: clientIds });
}

export const setPortfolioGoals = function (portfolioId: number, productsGroupId: number, year: number, goals: number[]): Promise<AxiosResponse> {
    return axiosInstance.put<number[], AxiosResponse>(`Portfolios/${portfolioId}/Goals/${year}/ProductsGroups/${productsGroupId}`, goals);
}


// Goals
export const getGoals = function (year: number): Promise<AxiosResponse<PortfolioGoal[]>> {
    return axiosInstance.get<unknown, AxiosResponse<PortfolioGoal[]>>(`Goals/${year}`);
}

// BusinessSegments
export const getBusinessSegments = function (): Promise<AxiosResponse<BusinessSegment[]>> {
    return axiosInstance.get<unknown, AxiosResponse<BusinessSegment[]>>('BusinessSegments');
}

export const createBusinessSegment = function (businessSegment: BusinessSegment): Promise<AxiosResponse<BusinessSegment>> {
    return axiosInstance.post<BusinessSegment, AxiosResponse<BusinessSegment>>('BusinessSegments', businessSegment);
}

export const editBusinessSegment = function (businessSegment: BusinessSegment): Promise<AxiosResponse> {
    return axiosInstance.put<BusinessSegment, AxiosResponse>(`BusinessSegments/${businessSegment.id}`, businessSegment);
}

export const deleteBusinessSegment = function (businessSegment: BusinessSegment): Promise<AxiosResponse> {
    return axiosInstance.delete<unknown, AxiosResponse>(`BusinessSegments/${businessSegment.id}`);
}

export const createPortfolio = function (businessSegment: BusinessSegment, portfolio: Portfolio): Promise<AxiosResponse<Portfolio>> {
    return axiosInstance.post<Portfolio, AxiosResponse<Portfolio>>(`BusinessSegments/${businessSegment.id}/Portfolios`, portfolio);
}

// System parameters
export const getSystemParameters = function (): Promise<AxiosResponse<SystemParameters>> {
    return axiosInstance.get<unknown, AxiosResponse<SystemParameters>>('SystemParameters');
}

export const setSystemParameters = function (parameters: SystemParameters): Promise<AxiosResponse<SystemParameters>> {
    return axiosInstance.put<SystemParameters, AxiosResponse<SystemParameters>>('SystemParameters', parameters);
}

export const setLogo = function (file: File | undefined = undefined): Promise<AxiosResponse<string>> {
    if (file) {
        const fd = new FormData();
        fd.append('file', file);
        return axiosInstance.put<File, AxiosResponse<string>>('SystemParameters/Logo', fd, { headers: { 'Content-Type': 'multipart/form-data' } });
    }
    return axiosInstance.put<File, AxiosResponse<string>>('SystemParameters/Logo');
}

export const getLogo = function (): Promise<AxiosResponse<string>> {
    return axiosInstance.get<SystemParameters, AxiosResponse<string>>('SystemParameters/Logo');
}

// Non working days
export const getNonWorkingDays = function (year: number): Promise<AxiosResponse<Dayjs[]>> {
    return axiosInstance.get<unknown, AxiosResponse<Dayjs[]>>(`NonWorkingDays/${year}`);
}

export const setNonWorkingDays = function (dates: Dayjs[]): Promise<AxiosResponse> {
    return axiosInstance.put<Dayjs[], AxiosResponse>('NonWorkingDays', dates);
}

// SalespersonTypes
export const getSalespersonTypes = function (all?: boolean): Promise<AxiosResponse<SalespersonType[]>> {
    const params = { all };
    return axiosInstance.get<unknown, AxiosResponse<SalespersonType[]>>('SalespersonTypes', { params });
}

export const setSalespersonTypeActivationStatus = function (type: SalespersonType, isActive: boolean): Promise<AxiosResponse> {
    return axiosInstance.put<boolean, AxiosResponse>(`SalespersonTypes/${type.id}/ActivationStatus/${isActive}`);
}

export const setContactTypes = function (salespersonTypeId: number, contactTypeIds: number[]): Promise<AxiosResponse> {
    return axiosInstance.put<number[], AxiosResponse>(`SalespersonTypes/${salespersonTypeId}/ContactTypes`, contactTypeIds);
}

// ContactTypes
export const getContactTypes = function (all?: boolean): Promise<AxiosResponse<ContactType[]>> {
    const params = { all };
    return axiosInstance.get<unknown, AxiosResponse<ContactType[]>>('ContactTypes', { params });
}

export const setContactTypeActivationStatus = function (type: ContactType, isActive: boolean): Promise<AxiosResponse> {
    return axiosInstance.put<boolean, AxiosResponse>(`ContactTypes/${type.id}/ActivationStatus/${isActive}`);
}

// Contacts
export const getContacts = function (params: ContactsQuery): Promise<AxiosResponse<Contact[]>> {
    return axiosInstance.get<ContactsQuery, AxiosResponse<Contact[]>>(`Contacts`, { params });
}

export const scheduleContact = function (scheduleContact: ScheduleContactData): Promise<AxiosResponse> {
    return axiosInstance.post<ScheduleContactData, AxiosResponse>(`Contacts/Scheduled`, scheduleContact);
}

export const editContact = function (contact: Contact, editContact: ContactData): Promise<AxiosResponse> {
    return axiosInstance.put<ContactData, AxiosResponse>(`Contacts/${contact.id}`, editContact);
}

export const deleteContact = function (contact: Contact): Promise<AxiosResponse> {
    return axiosInstance.delete<unknown, AxiosResponse>(`Contacts/${contact.id}`);
}

export const informScheduledContact = function (contact: Contact, informScheduledContact: InformScheduledContactData): Promise<AxiosResponse> {
    return axiosInstance.put<InformScheduledContactData, AxiosResponse>(`Contacts/Scheduled/${contact.id}`, informScheduledContact);
}

export const informUnscheduledContact = function (informUnscheduledContact: InformUnscheduledContactData): Promise<AxiosResponse> {
    return axiosInstance.post<InformUnscheduledContactData, AxiosResponse>(`Contacts/Unscheduled`, informUnscheduledContact)
}

// Objectives
export const getObjectives = function (all?: boolean): Promise<AxiosResponse<Objective[]>> {
    const params = { all };
    return axiosInstance.get<unknown, AxiosResponse<Objective[]>>(`Objectives`, { params });
}

export const setObjectiveActivationStatus = function (objective: Objective, isActive: boolean): Promise<AxiosResponse> {
    return axiosInstance.put<boolean, AxiosResponse>(`Objectives/${objective.id}/ActivationStatus/${isActive}`);
}

// Users
export const getUsers = function (): Promise<AxiosResponse<User[]>> {
    return axiosInstance.get<unknown, AxiosResponse<User[]>>(`Users`);
}

export const getUser = function (id: number): Promise<AxiosResponse<User>> {
    return axiosInstance.get<unknown, AxiosResponse<User>>(`Users/${id}`);
}

export const createUser = function (user: User): Promise<AxiosResponse> {
    return axiosInstance.post<User, AxiosResponse>(`Users`, user);
}

export const editUser = function (user: User): Promise<AxiosResponse> {
    return axiosInstance.put<User, AxiosResponse>(`Users/${user.id}`, user);
}

export const deleteUser = function (user: User): Promise<AxiosResponse> {
    return axiosInstance.delete<unknown, AxiosResponse>(`Users/${user.id}`);
}

export const setUserActivationStatus = function (nexus: User, isActive: boolean): Promise<AxiosResponse> {
    return axiosInstance.put<boolean, AxiosResponse>(`Users/${nexus.id}/ActivationStatus/${isActive}`);
}

export const setUserRoles = function (userId: number, roles: RoleName[] | undefined): Promise<AxiosResponse> {
    return axiosInstance.put<string[], AxiosResponse>(`Users/${userId}/Roles`, roles);
}

// Salespeople
export const getSalespeople = function (params?: SalespeopleQuery): Promise<AxiosResponse<Salesperson[]>> {
    if (params) {
        return axiosInstance.get<unknown, AxiosResponse<Salesperson[]>>(`Salespeople`, { params });
    }
    return axiosInstance.get<unknown, AxiosResponse<Salesperson[]>>(`Salespeople`);
}

export const getSalesperson = function (id: number): Promise<AxiosResponse<Salesperson>> {
    return axiosInstance.get<unknown, AxiosResponse<Salesperson>>(`Salespeople/${id}`);
}

export const createSalesperson = function (user: Salesperson): Promise<AxiosResponse> {
    return axiosInstance.post<Salesperson, AxiosResponse>(`Salespeople`, user);
}

export const editSalesperson = function (user: Salesperson): Promise<AxiosResponse> {
    return axiosInstance.put<Salesperson, AxiosResponse>(`Salespeople/${user.id}`, user);
}

export const setSalespersonType = function (salesperson: Salesperson, typeId: number): Promise<AxiosResponse> {
    return axiosInstance.put<unknown, AxiosResponse>(`Salespeople/${salesperson.id}/SalespersonType/${typeId}`);
}

// Quotes
export const getQuotes = function (params: QuotesQuery): Promise<AxiosResponse<PaginatedResult<Quote>>> {
    return axiosInstance.get<QuotesQuery, AxiosResponse<PaginatedResult<Quote>>>('Quotes', { params });
}

export const getQuote = function (quoteId: number): Promise<AxiosResponse<Quote>> {
    return axiosInstance.get<unknown, AxiosResponse<Quote>>(`Quotes/${quoteId}`);
}

export const createQuote = function (quote: QuoteData): Promise<AxiosResponse> {
    return axiosInstance.post<QuoteData, AxiosResponse>('Quotes', quote);
}

export const editQuote = function (quoteId: number, quote: QuoteData): Promise<AxiosResponse> {
    return axiosInstance.put<QuoteData, AxiosResponse>(`Quotes/${quoteId}`, quote);
}

export const deleteQuote = function (quoteId: number): Promise<AxiosResponse> {
    return axiosInstance.delete<unknown, AxiosResponse>(`Quotes/${quoteId}`);
}

export const addGapDocument = function (quoteId: number, gapDocument: GapDocumentData): Promise<AxiosResponse> {
    return axiosInstance.post<GapDocumentData, AxiosResponse>(`Quotes/${quoteId}/GapDocuments`, gapDocument);
}

export const getQuoteGapDocuments = function (quoteId: number): Promise<AxiosResponse<GapDocument[]>> {
    return axiosInstance.get<unknown, AxiosResponse<GapDocument[]>>(`Quotes/${quoteId}/GapDocuments`);
}

export const getQuoteSales = function (quoteId: number): Promise<AxiosResponse<Sale[]>> {
    return axiosInstance.get<unknown, AxiosResponse<Sale[]>>(`Quotes/${quoteId}/Sales`);
}

export const getQuoteContacts = function (quoteId: number): Promise<AxiosResponse<Contact[]>> {
    return axiosInstance.get<unknown, AxiosResponse<Contact[]>>(`Quotes/${quoteId}/Contacts`);
}

export const loseQuote = function (quoteId: number): Promise<AxiosResponse> {
    return axiosInstance.put<unknown, AxiosResponse>(`Quotes/${quoteId}/LostStatus`);
}

export const getQuotesExcel = function (params: QuotesQuery): Promise<void> {
    return axiosInstance.get<unknown, AxiosResponse<Blob>>(`Quotes/Excel`, { params, responseType: 'blob' })
        .then((response: AxiosResponse<Blob>) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            let fileName = 'Cotizaciones';
            if (params.status) {
                // TODO: duplicado en components/QuoteStatusChip.vue, computed.text()
                switch (params.status) {
                    case QuoteStatus.BDP:
                        fileName = `${fileName} en BDP`;
                        break;
                    case QuoteStatus.BDN:
                        fileName = `${fileName} en BDN`;
                        break;
                    case QuoteStatus.Gap:
                        fileName = `${fileName} en Brecha`;
                        break;
                    case QuoteStatus.Billed:
                        fileName = `${fileName} Facturadas`;
                        break;
                    case QuoteStatus.Lost:
                        fileName = `${fileName} Perdidas`;
                        break;
                    case QuoteStatus.Expired:
                        fileName = `${fileName} Expiradas`;
                        break;
                    default:
                        break;

                }
            }
            link.setAttribute('download', `${fileName} ${dayjs().format('DD-MM-YYYY')}.xlsx`);
            document.body.appendChild(link);
            link.click();
        });
}

// GapDocuments
export const getGapDocuments = function (params: GapDocumentsQuery): Promise<AxiosResponse<PaginatedResult<GapDocument>>> {
    return axiosInstance.get<GapDocumentsQuery, AxiosResponse<PaginatedResult<GapDocument>>>('GapDocuments', { params });
}

export const getGapDocument = function (gapDocumentId: number): Promise<AxiosResponse<GapDocument>> {
    return axiosInstance.get<unknown, AxiosResponse<GapDocument>>(`GapDocuments/${gapDocumentId}`);
}

export const editGapDocument = function (gapDocumentId: number, gapDocument: GapDocumentData): Promise<AxiosResponse> {
    return axiosInstance.put<GapDocumentData, AxiosResponse>(`GapDocuments/${gapDocumentId}`, gapDocument);
}

export const deleteGapDocument = function (gapDocumentId: number): Promise<AxiosResponse> {
    return axiosInstance.delete<unknown, AxiosResponse>(`GapDocuments/${gapDocumentId}`);
}

export const addSale = function (gapDocumentId: number, sale: SaleData): Promise<AxiosResponse> {
    return axiosInstance.post<SaleData, AxiosResponse>(`GapDocuments/${gapDocumentId}/Sales`, sale);
}

export const confirmGapDocument = function (gapDocumentId: number): Promise<AxiosResponse> {
    return axiosInstance.post<unknown, AxiosResponse>(`GapDocuments/${gapDocumentId}/Confirm`);
}

export const rejectGapDocument = function (gapDocumentId: number): Promise<AxiosResponse> {
    return axiosInstance.post<unknown, AxiosResponse>(`GapDocuments/${gapDocumentId}/Reject`);
}

export const getGapDocumentSales = function (gapDocumentId: number): Promise<AxiosResponse<Sale[]>> {
    return axiosInstance.get<unknown, AxiosResponse<Sale[]>>(`GapDocuments/${gapDocumentId}/Sales`);
}

// Sales
export const getSales = function (params: SalesQuery): Promise<AxiosResponse<PaginatedResult<Sale>>> {
    return axiosInstance.get<SalesQuery, AxiosResponse<PaginatedResult<Sale>>>('Sales', { params });
}

export const getSale = function (saleId: number): Promise<AxiosResponse<Sale>> {
    return axiosInstance.get<unknown, AxiosResponse<Sale>>(`Sales/${saleId}`);
}

export const editSale = function (saleId: number, gapDocument: SaleData): Promise<AxiosResponse> {
    return axiosInstance.put<SaleData, AxiosResponse>(`Sales/${saleId}`, gapDocument);
}

export const deleteSale = function (saleId: number): Promise<AxiosResponse> {
    return axiosInstance.delete<unknown, AxiosResponse>(`Sales/${saleId}`);
}

export const confirmSale = function (saleId: number): Promise<AxiosResponse> {
    return axiosInstance.post<unknown, AxiosResponse>(`Sales/${saleId}/Confirm`);
}

export const rejectSale = function (saleId: number): Promise<AxiosResponse> {
    return axiosInstance.post<unknown, AxiosResponse>(`Sales/${saleId}/Reject`);
}

// AdministrativeDivisions
export const getCommunes = function (): Promise<AxiosResponse<Commune[]>> {
    return axiosInstance.get<unknown, AxiosResponse<Commune[]>>('AdministrativeDivisions/Communes');
}

export const getCountries = function (): Promise<AxiosResponse<Country[]>> {
    return axiosInstance.get<unknown, AxiosResponse<Country[]>>('AdministrativeDivisions/Countries');
}

// Indicators
export const getIndicators = function (): Promise<AxiosResponse<PortfolioIndicator[]>> {
    return axiosInstance.get<unknown, AxiosResponse<PortfolioIndicator[]>>('Indicators');
}

export const getBdn = function (): Promise<AxiosResponse<number>> {
    return axiosInstance.get<unknown, AxiosResponse<number>>('Indicators/BDN');
}

export const getBdp = function (): Promise<AxiosResponse<number>> {
    return axiosInstance.get<unknown, AxiosResponse<number>>('Indicators/BDP');
}

export const getGap = function (): Promise<AxiosResponse<number>> {
    return axiosInstance.get<unknown, AxiosResponse<number>>('Indicators/Gap');
}

export const getSalesIndicator = function (): Promise<AxiosResponse<number>> {
    return axiosInstance.get<unknown, AxiosResponse<number>>('Indicators/Sales');
}


export const getClientsCount = function (): Promise<AxiosResponse<number>> {
    return axiosInstance.get<unknown, AxiosResponse<number>>('Indicators/ClientsCount');
}

export const getCriticalClientsCount = function (): Promise<AxiosResponse<number>> {
    return axiosInstance.get<unknown, AxiosResponse<number>>('Indicators/CriticalClientsCount');
}

export const getTodaysScheduledContactsCount = function (): Promise<AxiosResponse<number>> {
    return axiosInstance.get<unknown, AxiosResponse<number>>('Indicators/TodaysScheduledContactsCount');
}

export const getDailyCoverages = function (year: number, month: number, portfolioId: number | undefined = undefined, clientId: number | undefined = undefined): Promise<AxiosResponse<DailyCoverages[]>> {
    const params = {
        year, month, portfolioId, clientId
    };
    return axiosInstance.get<unknown, AxiosResponse<DailyCoverages[]>>(`Indicators/DailyCoverages`, { params });
}

export const getBDNQuotesCount = function (): Promise<AxiosResponse<number>> {
    return axiosInstance.get<unknown, AxiosResponse<number>>('Indicators/BDNQuotesCount');
}

export const getIndicatorsExcel = function (): Promise<void> {
    return axiosInstance.get<unknown, AxiosResponse<Blob>>(`Indicators/Excel`, { responseType: 'blob' })
        .then((response: AxiosResponse<Blob>) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Indicadores por Cartera ${dayjs().format('DD-MM-YYYY')}.xlsx`);
            document.body.appendChild(link);
            link.click();
        });
}

export const getCoverageExcel = function (year: number, month: number, portfolioId: number | undefined = undefined, clientId: number | undefined = undefined): Promise<void> {
    const params = {
        year, month, portfolioId, clientId
    };
    return axiosInstance.get<unknown, AxiosResponse<Blob>>(`Indicators/DailyCoverages/Excel`, { params, responseType: 'blob' })
        .then((response: AxiosResponse<Blob>) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Cobertura ${dayjs().format('DD-MM-YYYY')}.xlsx`);
            document.body.appendChild(link);
            link.click();
        });
}



// Confirmations
export const getConfirmations = function (): Promise<AxiosResponse<Confirmation[]>> {
    return axiosInstance.get<unknown, AxiosResponse<Confirmation[]>>('Confirmations');
}

export const getPendingConfirmationsCount = function (): Promise<AxiosResponse<number>> {
    return axiosInstance.get<unknown, AxiosResponse<number>>('Confirmations/PendingCount');
}

// DailyReports
export const getDailyReports = function (params: PaginatedQuery): Promise<AxiosResponse<PaginatedResult<DailyReport>>> {
    return axiosInstance.get<unknown, AxiosResponse<PaginatedResult<DailyReport>>>('DailyReports', { params });
}

export const getPdf = function (dailyReportId: number): void {
    axiosInstance.get<unknown, AxiosResponse<Blob>>(`DailyReports/${dailyReportId}/Pdf`, { responseType: 'blob' })
        .then((response: AxiosResponse<Blob>) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `daily_report_${dailyReportId}.pdf`);
            document.body.appendChild(link);
            link.click();
        });
}

export const getDailyReportPayload = function (dailyReportId: number): Promise<AxiosResponse<DailyReportPayload>> {
    return axiosInstance.get<unknown, AxiosResponse<DailyReportPayload>>(`DailyReports/${dailyReportId}/Preview`);
}

// Reports
export const getGoalAchievments = function (year: number): Promise<AxiosResponse<GoalAchievments>> {
    return axiosInstance.get<unknown, AxiosResponse<GoalAchievments>>(`Reports/${year}/GoalAchievments`);
}

export const getPortfolioComposition = function (): Promise<AxiosResponse<PortfolioComposition>> {
    return axiosInstance.get<unknown, AxiosResponse<PortfolioComposition>>('Reports/PortfolioComposition');
}

export const getOperativeBalance = function (salespersonId: number): Promise<AxiosResponse<OperativeBalance[]>> {
    return axiosInstance.get<unknown, AxiosResponse<OperativeBalance[]>>(`Reports/OperativeBalance/Salesperson/${salespersonId}`);
}

export const getOperativeBalanceExcel = function (): Promise<void> {
    return axiosInstance.get<unknown, AxiosResponse<Blob>>(`Reports/OperativeBalance/Excel`, { responseType: 'blob' })
        .then((response: AxiosResponse<Blob>) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Balance Operativo ${dayjs().format('DD-MM-YYYY')}.xlsx`);
            document.body.appendChild(link);
            link.click();
        });
}

// Sectors
export const getSectors = function (): Promise<AxiosResponse<Sector[]>> {
    return axiosInstance.get<unknown, AxiosResponse<Sector[]>>('Sectors');
}

export const createSector = function (sector: Sector): Promise<AxiosResponse<Sector>> {
    return axiosInstance.post<Sector, AxiosResponse<Sector>>('Sectors', sector);
}

export const editSector = function (sector: Sector): Promise<AxiosResponse> {
    return axiosInstance.put<unknown, AxiosResponse>(`Sectors/${sector.id}`, sector);
}

export const deleteSector = function (sector: Sector): Promise<AxiosResponse> {
    return axiosInstance.delete<unknown, AxiosResponse>(`Sectors/${sector.id}`);
}

// Customizables
export const getCustomTextFields = function (controllerName: string): Promise<AxiosResponse<CustomTextField[]>> {
    return axiosInstance.get<unknown, AxiosResponse<CustomTextField[]>>(`${controllerName}/CustomTextFields`);
}

export const createCustomTextField = function (controllerName: string, field: CustomTextField): Promise<AxiosResponse> {
    return axiosInstance.post<CustomTextField, AxiosResponse>(`${controllerName}/CustomTextFields`, field);
}

export const editCustomTextField = function (controllerName: string, id: number, field: CustomTextField): Promise<AxiosResponse> {
    return axiosInstance.put<CustomTextField, AxiosResponse>(`${controllerName}/CustomTextFields/${id}`, field);
}

export const deleteCustomTextField = function (controllerName: string, id: number): Promise<AxiosResponse> {
    return axiosInstance.delete<unknown, AxiosResponse>(`${controllerName}/CustomTextFields/${id}`);
}

export const getCustomSelectableFields = function (controllerName: string): Promise<AxiosResponse<CustomSelectableField[]>> {
    return axiosInstance.get<unknown, AxiosResponse<CustomSelectableField[]>>(`${controllerName}/CustomSelectableFields`);
}

export const createCustomSelectableField = function (controllerName: string, field: CustomSelectableField): Promise<AxiosResponse> {
    return axiosInstance.post<CustomSelectableField, AxiosResponse>(`${controllerName}/CustomSelectableFields`, field);
}

export const editCustomSelectableField = function (controllerName: string, id: number, field: CustomSelectableField): Promise<AxiosResponse> {
    return axiosInstance.put<CustomSelectableField, AxiosResponse>(`${controllerName}/CustomSelectableFields/${id}`, field);
}

export const deleteCustomSelectableField = function (controllerName: string, id: number): Promise<AxiosResponse> {
    return axiosInstance.delete<unknown, AxiosResponse>(`${controllerName}/CustomSelectableFields/${id}`);
}

export const createCustomOption = function (controllerName: string, selectableFieldId: number, option: CustomOption): Promise<AxiosResponse> {
    return axiosInstance.post<CustomOption, AxiosResponse<CustomTextField[]>>(`${controllerName}/CustomSelectableFields/${selectableFieldId}/CustomOptions`, option);
}

export const editCustomOption = function (controllerName: string, id: number, field: CustomOption): Promise<AxiosResponse> {
    return axiosInstance.put<CustomOption, AxiosResponse>(`${controllerName}/CustomOptions/${id}`, field);
}

export const deleteCustomOption = function (controllerName: string, id: number): Promise<AxiosResponse> {
    return axiosInstance.delete<unknown, AxiosResponse>(`${controllerName}/CustomOptions/${id}`);
}