
import { RoleName, User, ValidationErrors, roleNameString } from "@/models";
import Vue from "vue";

export default Vue.extend({
  props: {
    user: Object as () => User,
    errors: Object as () => ValidationErrors,
    hideRoles: Boolean,
  },
  data() {
    return {
      roles: [
        { value: RoleName.Administrator, text: roleNameString(RoleName.Administrator) },
        { value: RoleName.Director, text: roleNameString(RoleName.Director) },
        // { value: RoleName.Supervisor, text: roleNameString(RoleName.Supervisor) },
        // { value: RoleName.Salesperson, text: roleNameString(RoleName.Salesperson) },
      ]
    };
  }
});
