
import {
  confirmSale,
  deleteSale,
  editSale,
  getPortfolios,
  getSales,
  rejectSale
} from "@/api";
import ButtonToqui, { Kind as ToquiKind } from "@/components/ButtonToqui.vue";
import ButtonCircle, { Kind } from "@/components/ButtonCircle.vue";
import RequireConfirmation from "@/components/RequireConfirmation.vue";
import Modal from "@/components/Modal.vue";
import {
  Nexus,
  PaginatedResult,
  Sale,
  SaleData,
  SalesQuery,
  SnackbarColor,
  ValidationErrors,
  RoleName,
  GapDocument,
  Quote,
  QuoteStatus,
  Client,
  Portfolio
} from "@/models";
import { authorizeRoles } from "@/helpers";
import { AxiosError, AxiosResponse } from "axios";
import SaleForm from "@/views/sales/SaleForm.vue";
import dayjs from "dayjs";
import Vue from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import { MutationTypes } from "@/store";
import numeral from "numeral";

export interface SalesTableData {
  // Table data, filters, pagination and errors.
  sales: Sale[];
  totalSales: number;
  loading: boolean;
  options: DataOptions;
  headers: DataTableHeader[];
  search?: string;
  error: string | null;
  portfoliosForFilter: Portfolio[];
  portfolioId: number;

  // Sale edition.
  showEditForm: boolean;
  editedSaleId: number;
  editing: boolean;

  // Sale deletion.
  showDeleteModal: boolean;
  deletedSaleId: number;
  deleting: boolean;

  // Nexus selection (required to display contact form).
  showNexusModal: boolean;
  nexuses: Nexus[];
  followUpNexusId: number;
  followUpSaleId: number;

  // Purchase creation.
  showSaleCreateForm: boolean;
  creatingSale: boolean;
  saleSaleId: number;

  // Purchase order form.
  saleData: SaleData;

  // Validation errors (for all forms and requests).
  validationErrors: ValidationErrors;

  // Purchase order confirmation.
  confirmedSaleId: number;
  showConfirmationModal: boolean;

  // Purchase order rejection.
  rejectedSaleId: number;
  showRejectionModal: boolean;

  // Confirmation or rejection.
  processing: boolean;
  saleCode: string;

  // Show purchase order and quote.
  showQuoteAndPo: boolean;
  shownSale: Sale;

  // Totals
  computedValues: { [key: string]: number };
}

const initialOptions: DataOptions = {
  sortBy: ["value"],
  sortDesc: [true],
  page: 1,
  itemsPerPage: 10,
  groupBy: [],
  groupDesc: [false],
  multiSort: false,
  mustSort: true
};

const defaultSaleData: SaleData = {
  code: "",
  value: 0,
  date: dayjs()
};

const defaultClient: Client = {
  id: 0,
  name: "",
  portfolios: [],
  facilities: [],
  isConfirmed: false,
  textFields: {},
  selectableFields: {},
  isDeletable: false
};

const defaultPortfolio: Portfolio = {
  id: 0,
  name: "",
  clientsCount: 0
};

const defaultQuote: Quote = {
  id: 0,
  code: "",
  value: 0,
  description: "",
  date: dayjs(),
  status: QuoteStatus.BDN,
  expirationDate: dayjs(),
  client: { ...defaultClient },
  portfolio: { ...defaultPortfolio },
  followUpsCount: 0,
  gapDocumentsValue: 0,
  salesValue: 0,
  productsGroup: {
    id: 0,
    name: "",
    controlHorizonDays: 0,
    deletable: false
  },
  isDominant: false,
  isAnomalous: false
};

const defaultGapDocument: GapDocument = {
  id: 0,
  code: "",
  value: 0,
  date: dayjs(),
  confirmed: false,
  quote: { ...defaultQuote },
  salesValue: 0
};

const defaultSale: Sale = {
  id: 0,
  code: "",
  value: 0,
  date: dayjs(),
  confirmed: false,
  client: { ...defaultClient },
  gapDocument: { ...defaultGapDocument },
  portfolio: { ...defaultPortfolio }
};

export default Vue.extend({
  components: {
    ButtonToqui,
    ButtonCircle,
    Modal,
    SaleForm,
    RequireConfirmation
  },
  data(): SalesTableData {
    return {
      totalSales: 0,
      sales: [],
      search: "",
      loading: true,
      options: initialOptions,
      error: null,
      portfoliosForFilter: [],
      portfolioId: 0,
      headers: [
        {
          text: "Código",
          align: "start",
          sortable: false,
          value: "code",
          width: "18%"
        },
        {
          text: `Valor [${this.$store.state.parameters.currency}]`,
          align: "start",
          sortable: true,
          value: "value"
        },
        {
          text: "Cliente",
          align: "start",
          sortable: false,
          value: "client",
          width: "28%"
        },
        {
          text: "Fecha de Emisión",
          align: "start",
          sortable: true,
          value: "date"
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: "actions"
        }
      ],
      showEditForm: false,
      editedSaleId: 0,
      editing: false,
      showDeleteModal: false,
      deletedSaleId: 0,
      deleting: false,
      saleData: { ...defaultSaleData },
      validationErrors: {},
      showNexusModal: false,
      nexuses: [],
      followUpNexusId: 0,
      followUpSaleId: 0,
      showSaleCreateForm: false,
      creatingSale: false,
      saleSaleId: 0,
      confirmedSaleId: 0,
      processing: false,
      showConfirmationModal: false,
      rejectedSaleId: 0,
      showRejectionModal: false,
      saleCode: "",
      showQuoteAndPo: false,
      shownSale: { ...defaultSale },
      computedValues: {}
    };
  },
  mounted() {
    this.getDataFromApi();
    getPortfolios().then((response: AxiosResponse<Portfolio[]>) => {
      // This map is to create a separate array than the used to filter this view by portfolio.
      this.portfoliosForFilter = response.data;
      this.portfoliosForFilter.unshift({
        id: 0,
        name: "Todas las carteras",
        clientsCount: 0
      });
    });
  },
  methods: {
    authorizeRoles: (roles: RoleName[]): boolean => authorizeRoles(roles),
    numeral,
    getDataFromApi() {
      this.loading = true;
      this.error = null;
      const params: SalesQuery = {
        page: this.options.page,
        resultsPerPage: this.options.itemsPerPage,
        text: this.search,
        portfolioId: this.portfolioId === 0 ? undefined : this.portfolioId,
        sortBy: this.options.sortBy[0],
        sortDesc: this.options.sortDesc[0]
      };
      getSales(params)
        .then((response: AxiosResponse<PaginatedResult<Sale>>) => {
          this.sales = response.data.data;
          this.totalSales = response.data.totalCount;
          this.computedValues = response.data.computedValues;
          this.loading = false;
        })
        .catch((error: AxiosError) => {
          console.log(error.config);
          this.sales = [];
          this.totalSales = 0;
          this.loading = false;
          this.error = "Ha habido un error consultando los datos solicitados.";
        });
    },
    startEdition(sale: Sale) {
      this.showEditForm = true;
      this.saleData = {
        code: sale.code,
        value: sale.value,
        date: sale.date
      };
      this.editedSaleId = sale.id;
    },
    editSale() {
      this.editing = true;
      editSale(this.editedSaleId, this.saleData)
        .then(() => {
          this.closeEditForm();
          this.getDataFromApi();
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.status == 400) {
            this.validationErrors = error.response.data.errors;
          }
        })
        .finally(() => {
          this.editing = false;
        });
    },
    closeEditForm() {
      this.showEditForm = false;
      this.editing = false;
      this.saleData = {
        ...defaultSaleData
      };
      this.editedSaleId = 0;
      this.validationErrors = {};
    },
    startDeletion(sale: Sale) {
      this.showDeleteModal = true;
      this.deletedSaleId = sale.id;
    },
    deleteSale() {
      deleteSale(this.deletedSaleId)
        .then(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `Cotización eliminada exitosamente.`,
            color: SnackbarColor.success
          });
          this.getDataFromApi();
        })
        .catch(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `No fue posible eliminar la cotización.`,
            color: SnackbarColor.error
          });
        })
        .finally(() => {
          this.showDeleteModal = false;
          this.deletedSaleId = 0;
        });
    },
    startConfirmation(sale: Sale) {
      this.showConfirmationModal = true;
      this.confirmedSaleId = sale.id;
      this.saleCode = sale.code;
    },
    confirm() {
      this.processing = true;
      confirmSale(this.confirmedSaleId)
        .then(() => {
          this.getDataFromApi();
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `Venta confirmada exitosamente.`,
            color: SnackbarColor.success
          });
        })
        .catch(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `Ha habido un problema confirmando la venta.`,
            color: SnackbarColor.error
          });
        })
        .finally(() => {
          this.cancelConfirmation();
        });
    },
    cancelConfirmation() {
      this.showConfirmationModal = false;
      this.confirmedSaleId = 0;
    },
    startRejection(sale: Sale) {
      this.showRejectionModal = true;
      this.rejectedSaleId = sale.id;
      this.saleCode = sale.code;
    },
    reject() {
      this.processing = true;
      rejectSale(this.rejectedSaleId)
        .then(() => {
          this.getDataFromApi();
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `Venta rechazada exitosamente.`,
            color: SnackbarColor.success
          });
        })
        .catch(() => {
          this.$store.commit(MutationTypes.SHOW_SNACK, {
            text: `Ha habido un problema rechazando la venta.`,
            color: SnackbarColor.error
          });
        })
        .finally(() => {
          this.cancelRejection();
        });
    },
    cancelRejection() {
      this.showRejectionModal = false;
      this.rejectedSaleId = 0;
    },
    displayQuoteAndPo(sale: Sale) {
      this.showQuoteAndPo = true;
      this.shownSale = { ...sale };
    }
  },
  computed: {
    ToquiKind: () => ToquiKind,
    Kind: () => Kind,
    RoleName: () => RoleName
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    }
  }
});
