
import { QuoteStatus } from "@/models";
import Vue from "vue";
export default Vue.extend({
  props: {
    kind: {
      type: String as () => QuoteStatus
    }
  },
  computed: {
    QuoteStatus: () => QuoteStatus,
    color() {
      switch (this.kind) {
        case QuoteStatus.BDP:
          return "teal";
        case QuoteStatus.BDN:
          return "green";
        case QuoteStatus.Gap:
          return "indigo";
        case QuoteStatus.Billed:
          return "blue-grey";
        case QuoteStatus.Lost:
          return "red";
        case QuoteStatus.Expired:
          return "purple";
        default:
          return "primary";
      }
    },
    text() {
      // TODO: duplicado en api/index.ts, getQuotesExcel
      switch (this.kind) {
        case QuoteStatus.BDP:
          return "BDP";
        case QuoteStatus.BDN:
          return "BDN";
        case QuoteStatus.Gap:
          return "Brecha";
        case QuoteStatus.Billed:
          return "Facturada";
        case QuoteStatus.Lost:
          return "Perdida";
        case QuoteStatus.Expired:
          return "Expirada";
        default:
          return "No definido";
      }
    },
    icon() {
      switch (this.kind) {
        case QuoteStatus.BDP:
          return "mdi-file-cad";
        case QuoteStatus.BDN:
          return "mdi-file-outline";
        case QuoteStatus.Gap:
          return "mdi-handshake-outline";
        case QuoteStatus.Billed:
          return "mdi-thumb-up-outline";
        case QuoteStatus.Lost:
          return "mdi-thumb-down-outline";
        case QuoteStatus.Expired:
          return "mdi-clock-alert-outline";
        default:
          return "No definido";
      }
    }
  }
});
