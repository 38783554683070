
import { authorizeRoles } from "@/helpers";
import ButtonCircle, { Kind } from "@/components/ButtonCircle.vue";
import { RoleName } from "@/models";
import Vue from "vue";
export default Vue.extend({
  props: {
    hideText: Boolean
  },
  components: {
    ButtonCircle
  },
  methods: {
    authorizeRoles: (roles: RoleName[]): boolean => authorizeRoles(roles)
  },
  computed: {
    Kind: () => Kind,
    RoleName: () => RoleName
  }
});
