
import { getSystemParameters, setSystemParameters } from "@/api";
import {
  SnackbarColor,
  SystemParameters,
  ValidationErrors,
  VForm
} from "@/models";
import { AxiosError, AxiosResponse } from "axios";
import Vue from "vue";
import { rules } from "@/helpers";
import { MutationTypes } from "@/store";
import ButtonToqui, { Kind as ToquiKind } from "@/components/ButtonToqui.vue";
import NumberInput from "@/components/NumberInput.vue";

interface ConfigurationState {
  parameters: SystemParameters;
  errors: ValidationErrors;
  loading: boolean;
  rules: { [key: string]: (value: string) => boolean | string };
}

export default Vue.extend({
  components: {
    ButtonToqui,
    NumberInput
  },
  data(): ConfigurationState {
    return {
      parameters: {
        maximumNumberOfDaysToPlan: 0,
        maximumNumberOfDailyVisits: 0,
        maximumNumberOfPlannedVisits: 0,
        closingRate: 0,
        currency: "",
        maintenanceToActivationDays: 0,
        daysToCritical: 0,
        companyName: "",
        logo: "",
        countryId: 45
      },
      errors: {},
      loading: false,
      rules
    };
  },
  mounted() {
    this.getSystemParameters();
  },
  methods: {
    getSystemParameters() {
      this.loading = true;
      this.errors = {};
      getSystemParameters().then(
        (response: AxiosResponse<SystemParameters>) => {
          this.parameters = response.data;
          this.loading = false;
        }
      );
    },
    setSystemParameters() {
      this.loading = true;
      this.errors = {};
      if (this.form.validate()) {
        setSystemParameters(this.parameters)
          .then((response: AxiosResponse<SystemParameters>) => {
            this.$store.commit(MutationTypes.SHOW_SNACK, {
              text: "Parámetros actualizados exitosamente.",
              color: SnackbarColor.success
            });
            this.$store.commit(
              MutationTypes.SET_SYSTEM_PARAMETERS,
              response.data
            );
            this.loading = false;
          })
          .catch((error: AxiosError) => {
            if (error.response && error.response.status == 400) {
              this.errors = error.response.data.errors;
            } else {
              this.$store.commit(MutationTypes.SHOW_SNACK, {
                text: "Ha habido un problema actualizando los parámetros.",
                color: SnackbarColor.error
              });
            }
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    }
  },
  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },
    ToquiKind: () => ToquiKind
  }
});
