import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors';

import es from 'vuetify/src/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#41acab',
        secondary: colors.teal,
        tertiary: colors.grey,
        accent: '#F16850',
        error: '#FF5252',
        info: '#609beb',
        success: '#4CAF50',
        warning: colors.yellow.darken3,
        base: '#19242b',
        anchor: '#4CBFBE',
        bluegrey6: '#eff3f4',
        bluegrey7: '#f3f6f7'
      },
    },
  },
  lang: {
    locales: { es },
    current: 'es'
  }
});
