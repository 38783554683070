// These must match API's resources.
import { Dayjs } from "dayjs";

export interface ValidationResult {
    errors: ValidationErrors;
    ruleSetsExecuted: string[];
}

export interface ValidationErrors { [key: string]: string[] }

export interface ActionError {
    errorMessage: string;
}

// Defining an interface to use VForm API methods.
export type VForm = Vue & { validate: () => boolean } & { reset: () => void } & { resetValidation: () => void }

export enum SnackbarColor {
    success = 'success',
    warning = 'warning',
    error = 'error'
}
export interface SnackbarData {
    text: string;
    color: SnackbarColor;
}

export interface Client {
    id: number;
    name: string;
    portfolios: ClientPortfolio[];
    facilities: Facility[];
    isConfirmed: boolean;
    sector?: Sector;
    textFields: { [key: string]: string };
    selectableFields: { [key: string]: CustomOption };
    isDeletable: boolean;
}

export interface CreateClientData extends Client {
    facility: Facility;
    nexus: Nexus;
    portfolioIds: number[];
}

export interface Facility {
    id: number;
    name: string;
    identifier: string;
    nexuses: Nexus[];
    isMain: boolean;
    client?: Client;
    isConfirmed: boolean;
    region: string;
    province: string;
    commune: string;
    communeId?: number;
    countryId: number;
    country: string;
    address: string;
}

export interface Commune {
    id: number;
    name: string;
    province: string;
    region: string;
}

export interface Country {
    id: number;
    name: string;
    code: string;
}

export interface Nexus {
    id: number;
    name: string;
    lastName: string;
    email: string;
    phone: string;
    mobilePhone: string;
    position: string;
    officeNumber: string;
    isMain: boolean;
    facility?: Facility;
    isConfirmed: boolean;
    isActive: boolean;
}

export interface Portfolio {
    id: number;
    name: string;
    clientsCount: number;
    salespersonId?: number;
    salespersonName?: string;
    salespersonLastName?: string;
    salespersonEmail?: string;
    salespersonType?: SalespersonType;
}

export enum PortfolioType {
    Development = "Development",
    Maintenance = "Maintenance",
    Activation = "Activation"
}

export function portfolioTypeNameString(portfolioType: PortfolioType): string {
    switch (portfolioType) {
        case PortfolioType.Development:
            return 'Desarrollo';
        case PortfolioType.Maintenance:
            return 'Mantención';
        case PortfolioType.Activation:
            return 'Activación';
        default:
            return 'No definido';
    }
}

export interface PaginatedResult<T> {
    data: T[];
    totalCount: number;
    counts: { [key: string]: number }
    computedValues: { [key: string]: number }
}

export interface PaginatedQuery {
    page: number;
    resultsPerPage: number;
    sortBy?: string;
    sortDesc?: boolean;
}

export interface ClientsQuery extends PaginatedQuery {
    portfolioType?: PortfolioType;
    text?: string;
    portfolioId?: number;
    confirmed?: boolean;
    isCritical?: boolean;
}

export interface ClientPortfolio extends Portfolio {
    portfolioType: PortfolioType;
    isCritical: boolean;
}

export enum RoleName {
    Salesperson = 'Salesperson',
    Supervisor = 'Supervisor',
    Director = 'Director',
    Administrator = 'Administrator',
    SuperAdministrator = 'SuperAdministrator'
}

export function roleNameString(role: RoleName): string {
    switch (role) {
        case RoleName.Salesperson:
            return 'Vendedor';
        case RoleName.Supervisor:
            return 'Supervisor';
        case RoleName.Director:
            return 'Director';
        case RoleName.Administrator:
            return 'Administrador';
        case RoleName.SuperAdministrator:
            return 'Super Administrador';
        default:
            return '';
    }
}

export interface User {
    id: number;
    name: string;
    lastName: string;
    userName: string;
    email: string;
    profileImage: string;
    isActive: boolean;
    isSalesperson?: boolean;
    roles: RoleName[];
}

export interface Claim {
    type: string;
    value: string;
}

export interface Credentials {
    username: string;
    password: string;
    useCookies: boolean;
}

export interface Sector {
    id: number;
    name: string;
    deletable: boolean;
}

export interface Brand {
    id: number;
    name: string;
    deletable: boolean;
}
export interface BusinessUnit {
    id: number;
    name: string;
    deletable: boolean;
}
export interface GeographicZone {
    id: number;
    name: string;
    deletable: boolean;
}
export interface ProductsGroup {
    id: number;
    name: string;
    deletable: boolean;
    controlHorizonDays: number;
}

export interface BusinessSegment {
    id: number;
    name: string;
    brand?: Brand;
    businessUnit?: BusinessUnit;
    geographicZone?: GeographicZone;
    portfolios: Portfolio[];
}

export interface SystemParameters {
    maximumNumberOfDaysToPlan: number;
    maximumNumberOfDailyVisits: number;
    maximumNumberOfPlannedVisits: number;
    closingRate: number;
    currency: string;
    maintenanceToActivationDays: number;
    daysToCritical: number;
    companyName: string;
    logo: string;
    countryId: number;
}

export interface SalespersonType {
    id: number;
    name: string;
    description: string;
    isActive: boolean;
    dailyContactsTarget: number;
    availableContactTypes: ContactType[];
}

export interface Salesperson extends User {
    salespersonType: SalespersonType;
    portfolios: Portfolio[];
}

export enum ContactKind {
    Cold = "Cold",
    Scheduled = "Scheduled",
    Inertia = "Inertia"
}

export const contactKindName = function (kind: ContactKind): string {
    switch (kind) {
        case ContactKind.Cold:
            return "En frío"
        case ContactKind.Scheduled:
            return "Agendado"
        case ContactKind.Inertia:
            return "Inercia"
        default:
            return "No definido";
    }
}

export interface ContactType {
    id: number;
    name: string;
    description: string;
    kind: ContactKind;
    isActive: boolean;
    icon: string;
    compatibleSalespersonTypes: SalespersonType[]
}

export interface Objective {
    id: number;
    name: string;
    isOperational: boolean;
    isActive: boolean;
}

export interface Contact {
    id: number;
    contactType: ContactType;
    nexus: Nexus;
    facility: Facility;
    client: Client;
    salesperson: Salesperson;
    objective: Objective;
    hasBeenInformed: boolean;
    hasBeenReported: boolean;
    aborted: boolean;
    dateTime: Dayjs;
    observations: string;
    agreements: string;
    nextActivity: string;
    quoteId?: number;
    portfolio: Portfolio;
}

export interface ContactsQuery {
    clientId?: number;
    salespersonId?: number;
    //! Date query parameters are managed as strings for serialization reasons.
    startDate?: string;
    endDate?: string;
}

export interface SalespeopleQuery {
    clientId?: number;
    includeInactive?: boolean;
}

export interface BaseScheduledContact {
    nexusId: number;
    dateTime: Dayjs;
    contactTypeId: number;
    objectiveId: number;
    portfolioId: number;
    quoteId?: number;
    observations: string;
}

export interface ContactData extends BaseScheduledContact {
    agreements: string;
    nextActivity: string;
}

export interface InformScheduledContactData {
    agreements: string;
    nextActivity: string;
}

export interface InformUnscheduledContactData extends BaseScheduledContact {
    agreements: string;
    nextActivity: string;
}

export interface ScheduleContactData extends BaseScheduledContact {
    notifyClient: boolean;
}

export enum QuoteStatus {
    BDP = "BDP",
    BDN = "BDN",
    Gap = "Gap",
    Billed = "Billed",
    Lost = "Lost",
    Expired = "Expired"
}

export interface QuotesQuery extends PaginatedQuery {
    status?: QuoteStatus;
    text?: string;
    salespersonId?: number;
    portfolioId?: number;
}

export interface QuoteData {
    code: string;
    value: number;
    date: Dayjs;
    clientId: number;
    portfolioId: number;
    forProject: boolean;
    productsGroupId: number;
    description: string;
}

export interface Quote {
    id: number;
    code: string;
    value: number;
    date: Dayjs;
    status: QuoteStatus;
    expirationDate: Dayjs;
    client: Client;
    portfolio: Portfolio;
    followUpsCount: number;
    gapDocumentsValue: number;
    salesValue: number;
    productsGroup: ProductsGroup;
    isDominant: boolean;
    isAnomalous: boolean;
    description: string;
}

export interface SalesQuery extends PaginatedQuery {
    text?: string;
    salespersonId?: number;
    portfolioId?: number;
}

export interface GapDocumentData {
    code: string;
    value: number;
    date: Dayjs;
}

export interface GapDocument {
    id: number;
    code: string;
    value: number;
    date: Dayjs;
    confirmed: boolean;
    quote: Quote;
    salesValue: number;
}

export interface PortfolioGoal {
    year: number;
    values: number[];
    portfolio: Portfolio;
    productsGroup: ProductsGroup;
}

export interface GapDocumentsQuery extends PaginatedQuery {
    text?: string;
    salespersonId?: number;
    portfolioId?: number;
    inGap?: boolean;
}

export interface SaleData {
    code: string;
    value: number;
    date: Dayjs;
}

export interface Sale {
    id: number;
    code: string;
    value: number;
    date: Dayjs;
    confirmed: boolean;
    client: Client;
    portfolio: Portfolio;
    gapDocument: GapDocument;
}

export interface PortfolioIndicator {
    bdn: number;
    gap: number;
    monthPerformance: number;
    yearPerformance: number;
    monthWonPerformance: number;
    coverage: number;
    portfolio: Portfolio;
    sales: number;
    bdp: number;
    bdnIndicator: number;
}

export enum ConfirmationType {
    Client = 'Client',
    Facility = 'Facility',
    Nexus = 'Nexus',
    GapDocument = 'GapDocument',
    Sale = 'Sale'
}
export interface Confirmation {
    id: string;
    resourceId: number;
    type: ConfirmationType;
    data: { [key: string]: string };
    user: User;
    date: Dayjs;
}

export interface DailyReport {
    id: number;
    date: Dayjs;
    salesperson: Salesperson;
    pdfFile: string;
    pdfGenerationStatus: string;
    pdfGenerated: boolean;
    pdfGenerationError: string;
    pdfDownloadError: string;
    sent: boolean;
    emailSendingError: string;
    contactsCount: number;
}

export interface ContactReport {
    nexusName: string;
    nexusEmail: string;
    portfolioName: string;
    clientLogoUrl: string;
    clientName: string;
    contactType: string;
    objectiveName: string;
    agreements: string;
    nextActivity: string;
}

export interface ContactTypeReport {
    name: string;
    description: string;
    icon: string;
    kind: string;
    kindIcon: string;
    count: number;
}

export interface DailyReportPayload {
    salespersonName: string;
    salespersonType: string;
    salespersonAvatarUrl: string;
    companyLogoUrl: string;
    companyName: string;
    date: string;
    reports: ContactReport[];
    contactTypesReport: ContactTypeReport[];
    aborted: boolean;
}

export interface GoalAchievments {
    goals: number[];
    sales: number[];
    labels: string[];
}

export interface PortfolioComposition {
    percentages: number[];
    labels: PortfolioType[];
}

export interface ChangePasswordData {
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}

export interface ActivateAccountData {
    accountActivationToken: string;
    password: string;
    passwordConfirmation: string;
    userId: number;
}

export interface ResetPasswordData {
    passwordResetToken: string;
    password: string;
    passwordConfirmation: string;
    userId: number;
}

export interface ForgotPasswordData {
    email: string;
}

export interface DailyCoverages {
    contactType: ContactType;
    values: number[];
}

export interface OperativeBalance {
    clientId: number;
    clientName: string;
    bdn: number;
    gap: number;
    bdp: number;
    scheduledContacts: number;
    coldContacts: number;
    inertiaContacts: number;
    billing: number;
}

export interface CustomTextField {
    id: number;
    name: string;
    description: string;
}

export interface CustomOption {
    id: number;
    value: string;
}

export interface CustomSelectableField {
    id: number;
    name: string;
    description: string;
    options: CustomOption[];
}

export interface DailyReportData {
    date: Dayjs;
    salespersonId: number;
}