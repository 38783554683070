
import {
  confirmClient,
  confirmFacility,
  confirmNexus,
  confirmGapDocument,
  confirmSale,
  getConfirmations,
  rejectClient,
  rejectFacility,
  rejectNexus,
  rejectGapDocument,
  rejectSale
} from "@/api";
import {
  Confirmation,
  ConfirmationType,
  RoleName,
  roleNameString
} from "@/models";
import { AxiosResponse } from "axios";
import ConfirmationTypeChip from "@/components/ConfirmationTypeChip.vue";
import RequireConfirmation from "@/components/RequireConfirmation.vue";
import ButtonToqui, { Kind as ToquiKind } from "@/components/ButtonToqui.vue";
import Modal from "@/components/Modal.vue";
import Vue from "vue";
import { DataTableHeader } from "vuetify";

interface ConfirmationsData {
  confirmations: Confirmation[];
  headers: DataTableHeader[];
  loading: boolean;

  // Confirmation
  showConfirmationModal: boolean;
  confirmedType: ConfirmationType;
  confirmedId: number;
  confirmedIndex: number;
  confirmationText: string;
  confirming: boolean;

  // Rejection
  showRejectionModal: boolean;
  rejectedType: ConfirmationType;
  rejectedId: number;
  rejectedIndex: number;
  rejectionText: string;
  rejecting: boolean;
}

export default Vue.extend({
  components: {
    ConfirmationTypeChip,
    RequireConfirmation,
    ButtonToqui,
    Modal
  },
  data(): ConfirmationsData {
    return {
      confirmations: [],
      loading: false,
      headers: [
        {
          text: "Usuario",
          align: "start",
          sortable: false,
          value: "user"
        },
        {
          text: "Tipo",
          align: "start",
          sortable: false,
          value: "type"
        },
        {
          text: "Información",
          align: "start",
          sortable: false,
          value: "data"
        },
        {
          text: "Confirmar/Rechazar",
          align: "start",
          sortable: false,
          value: "confirmation"
        }
      ],
      // Confirmation
      showConfirmationModal: false,
      confirmedType: ConfirmationType.Client,
      confirmedId: 0,
      confirmedIndex: 0,
      confirmationText: "",
      confirming: false,

      // Rejection
      showRejectionModal: false,
      rejectedType: ConfirmationType.Client,
      rejectedId: 0,
      rejectedIndex: 0,
      rejectionText: "",
      rejecting: false
    };
  },
  mounted() {
    this.getConfirmations();
  },
  methods: {
    roleNames(roles: RoleName[]): string {
      return roles.map((x: RoleName) => roleNameString(x)).join(", ");
    },
    getConfirmations() {
      this.loading = true;
      getConfirmations()
        .then((response: AxiosResponse<Confirmation[]>) => {
          this.confirmations = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
      // TODO: handle failures
    },
    startConfirmation(confirmation: Confirmation) {
      this.confirmedId = confirmation.resourceId;
      this.showConfirmationModal = true;
      this.confirmedIndex = this.confirmations.indexOf(confirmation);
      this.confirmedType = confirmation.type;
      switch (confirmation.type) {
        case ConfirmationType.Client:
          this.confirmationText = `¿Está seguro de confirmar el registro del cliente ${confirmation.data.Name}?`;
          break;
        case ConfirmationType.Facility:
          this.confirmationText = `¿Está seguro de confirmar el registro de la instalación ${confirmation.data.Name}?`;
          break;
        case ConfirmationType.Nexus:
          this.confirmationText = `¿Está seguro de confirmar el registro del nexo ${confirmation.data.Name} ${confirmation.data.LastName}?`;
          break;
        case ConfirmationType.GapDocument:
          this.confirmationText = `¿Está seguro de confirmar el registro del documento de brecha ${confirmation.data.Code}?`;
          break;
        case ConfirmationType.Sale:
          this.confirmationText = `¿Está seguro de confirmar el registro de la factura ${confirmation.data.Code}?`;
          break;
        default:
          break;
      }
    },
    confirm() {
      this.confirming = true;
      switch (this.confirmedType) {
        case ConfirmationType.Client:
          confirmClient(this.confirmedId)
            .then(() => {
              this.confirmations.splice(this.confirmedIndex, 1);
            })
            .finally(() => {
              this.showConfirmationModal = false;
              this.confirming = false;
            });
          // TODO: handle failures
          break;
        case ConfirmationType.Facility:
          confirmFacility(this.confirmedId)
            .then(() => {
              this.confirmations.splice(this.confirmedIndex, 1);
            })
            .finally(() => {
              this.showConfirmationModal = false;
              this.confirming = false;
            });
          // TODO: handle failures
          break;
        case ConfirmationType.Nexus:
          confirmNexus(this.confirmedId)
            .then(() => {
              this.confirmations.splice(this.confirmedIndex, 1);
            })
            .finally(() => {
              this.showConfirmationModal = false;
              this.confirming = false;
            });
          // TODO: handle failures
          break;
        case ConfirmationType.GapDocument:
          confirmGapDocument(this.confirmedId)
            .then(() => {
              this.confirmations.splice(this.confirmedIndex, 1);
            })
            .finally(() => {
              this.showConfirmationModal = false;
              this.confirming = false;
            });
          // TODO: handle failures
          break;
        case ConfirmationType.Sale:
          confirmSale(this.confirmedId)
            .then(() => {
              this.confirmations.splice(this.confirmedIndex, 1);
            })
            .finally(() => {
              this.showConfirmationModal = false;
              this.confirming = false;
            });
          // TODO: handle failures
          break;
        default:
          break;
      }
    },
    startRejection(confirmation: Confirmation) {
      this.rejectedId = confirmation.resourceId;
      this.showRejectionModal = true;
      this.rejectedIndex = this.confirmations.indexOf(confirmation);
      this.rejectedType = confirmation.type;
      switch (confirmation.type) {
        case ConfirmationType.Client:
          this.rejectionText = `¿Está seguro de rechazar el registro del cliente ${confirmation.data.Name}?`;
          break;
        case ConfirmationType.Facility:
          this.rejectionText = `¿Está seguro de rechazar el registro de la instalación ${confirmation.data.Name}?`;
          break;
        case ConfirmationType.Nexus:
          this.rejectionText = `¿Está seguro de rechazar el registro del nexo ${confirmation.data.Name} ${confirmation.data.LastName}?`;
          break;
        case ConfirmationType.GapDocument:
          this.rejectionText = `¿Está seguro de rechazar el registro del documento de brecha ${confirmation.data.Code}?`;
          break;
        case ConfirmationType.Sale:
          this.rejectionText = `¿Está seguro de rechazar el registro de la factura ${confirmation.data.Code}?`;
          break;
        default:
          break;
      }
    },
    reject() {
      this.rejecting = true;
      switch (this.rejectedType) {
        case ConfirmationType.Client:
          rejectClient(this.rejectedId)
            .then(() => {
              this.confirmations.splice(this.rejectedIndex, 1);
            })
            .finally(() => {
              this.showRejectionModal = false;
              this.rejecting = false;
            });
          // TODO: handle failures
          break;
        case ConfirmationType.Facility:
          rejectFacility(this.rejectedId)
            .then(() => {
              this.confirmations.splice(this.rejectedIndex, 1);
            })
            .finally(() => {
              this.showRejectionModal = false;
              this.rejecting = false;
            });
          // TODO: handle failures
          break;
        case ConfirmationType.Nexus:
          rejectNexus(this.rejectedId)
            .then(() => {
              this.confirmations.splice(this.rejectedIndex, 1);
            })
            .finally(() => {
              this.showRejectionModal = false;
              this.rejecting = false;
            });
          // TODO: handle failures
          break;
        case ConfirmationType.GapDocument:
          rejectGapDocument(this.rejectedId)
            .then(() => {
              this.confirmations.splice(this.rejectedIndex, 1);
            })
            .finally(() => {
              this.showRejectionModal = false;
              this.rejecting = false;
            });
          // TODO: handle failures
          break;
        case ConfirmationType.Sale:
          rejectSale(this.rejectedId)
            .then(() => {
              this.confirmations.splice(this.rejectedIndex, 1);
            })
            .finally(() => {
              this.showRejectionModal = false;
              this.rejecting = false;
            });
          // TODO: handle failures
          break;
        default:
          break;
      }
    }
  },
  computed: {
    ConfirmationType: () => ConfirmationType,
    ToquiKind: () => ToquiKind,
    largeViewport(): boolean {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
        case "md":
          return false;
        case "lg":
        case "xl":
        default:
          return true;
      }
    }
  }
});
