
import { setLogo } from "@/api";
import ButtonToqui, { Kind as ToquiKind } from "@/components/ButtonToqui.vue";
import Modal from "@/components/Modal.vue";
import { SnackbarColor } from "@/models";
import { MutationTypes } from "@/store";
import { AxiosResponse } from "axios";
import Vue from "vue";

interface LogoData {
  imageUrl: string;
  image: File | null;
  showRemovalConfirmationModal: boolean;
  removing: boolean;
}

export default Vue.extend({
  components: {
    ButtonToqui,
    Modal
  },
  data(): LogoData {
    return {
      imageUrl: this.$store.state.parameters.logo,
      image: null,
      showRemovalConfirmationModal: false,
      removing: false
    };
  },
  computed: {
    ToquiKind: () => ToquiKind
  },
  methods: {
    previewImage() {
      this.imageUrl = URL.createObjectURL(this.image);
    },
    uploadImage() {
      if (this.image) {
        setLogo(this.image)
          .then((response: AxiosResponse<string>) => {
            this.$store.commit(MutationTypes.SET_LOGO, response.data);
            this.$store.commit(MutationTypes.SHOW_SNACK, {
              text: "Su logo ha sido cargado exitosamente.",
              color: SnackbarColor.success
            });
          })
          .catch(() => {
            this.$store.commit(MutationTypes.SHOW_SNACK, {
              text: "Ha habido un problema cargando su logo.",
              color: SnackbarColor.error
            });
          });
      }
    },
    removeImage() {
      this.removing = true;
      setLogo()
      .then(() => {
        this.$store.commit(MutationTypes.SET_LOGO, '');
        this.$store.commit(MutationTypes.SHOW_SNACK, {
          text: "Su logo ha sido eliminado exitosamente.",
          color: SnackbarColor.success
        });
        this.imageUrl = '';
      })
      .catch(() => {
        this.$store.commit(MutationTypes.SHOW_SNACK, {
          text: "Ha habido un problema eliminando su logo.",
          color: SnackbarColor.error
        });
      })
      .finally(() => {
        this.removing = false;
        this.showRemovalConfirmationModal = false;
      });
    }
  }
});
