
import { getDailyReports, getPdf } from "@/api";
import {
  DailyReport,
  PaginatedQuery,
  PaginatedResult,
} from "@/models";
import ButtonCircle, { Kind } from "@/components/ButtonCircle.vue";
import { AxiosResponse } from "axios";
import Vue from "vue";
import { DataOptions, DataTableHeader } from "vuetify";

interface DailyReportsData {
  dailyReports: DailyReport[];
  totalDailyReports: number;
  options: DataOptions;
  headers: DataTableHeader[];
  loading: boolean;
}

const initialOptions: DataOptions = {
  sortBy: [],
  sortDesc: [false],
  page: 1,
  itemsPerPage: 10,
  groupBy: [],
  groupDesc: [false],
  multiSort: false,
  mustSort: true
};

export default Vue.extend({
  components: {
    ButtonCircle
  },
  computed: {
    Kind: () => Kind
  },
  data(): DailyReportsData {
    return {
      dailyReports: [],
      totalDailyReports: 0,
      options: { ...initialOptions },
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id"
        },
        {
          text: "Vendedor",
          align: "start",
          sortable: false,
          value: "salesperson"
        },
        {
          text: "Fecha",
          align: "start",
          sortable: false,
          value: "date"
        },
        {
          text: "Contactos Informados",
          align: "start",
          sortable: false,
          value: "contactsCount"
        },
        {
          text: "¿Enviado?",
          align: "start",
          sortable: false,
          value: "sent"
        },
        {
          text: "PDF",
          align: "start",
          sortable: false,
          value: "pdf"
        }
      ],
      loading: false,
    };
  },
  mounted() {
    this.getDailyReports();
  },
  methods: {
    getDailyReports() {
      this.loading = true;
      const params: PaginatedQuery = {
        page: this.options.page,
        resultsPerPage: this.options.itemsPerPage
      };
      getDailyReports(params)
        .then((response: AxiosResponse<PaginatedResult<DailyReport>>) => {
          this.dailyReports = response.data.data;
          this.totalDailyReports = response.data.totalCount;
        })
        .finally(() => {
          this.loading = false;
        });
      // TODO: handle failures here.
    },
    downloadPdf(dailyReportId: number) {
      getPdf(dailyReportId);
    }
  },
  watch: {
    options: {
      handler() {
        this.getDailyReports();
      },
      deep: true
    }
  }
});
