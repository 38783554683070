
import numeral from "numeral";
import { rules } from "@/helpers";
import Vue from "vue";

interface NumberInputData {
  formattedNumber: string;
  consideredRules: ((value: string) => boolean | string)[];
}

export default Vue.extend({
  props: {
    value: Number,
    label: String,
    required: Boolean,
    nullable: Boolean,
    error: Boolean,
    errorCount: {
      type: [Number, String],
      default: 2
    },
    errorMessages: {
      type: [String, Array],
      default: () => []
    },
    messages: {
      type: [String, Array],
      default: () => []
    },
    readonly: Boolean,
    rules: {
      type: Array,
      default: () => []
    },
    success: Boolean,
    successMessages: {
      type: [String, Array],
      default: () => []
    },
    prependIcon: String
  },
  data(): NumberInputData {
    return {
      formattedNumber: numeral(this.value).format(),
      consideredRules: []
    };
  },
  mounted() {
    if (this.required) {
      this.consideredRules = [rules.required, rules.onlyNumbers];
    } else {
      this.consideredRules = [rules.onlyNumbers];
    }
  },
  methods: {
    handleInput(value: string) {
      const number = numeral(value);
      const numberValue = number.value();
      if (!numberValue && !this.nullable) {
        this.formattedNumber = "0";
        this.$emit("input", 0);
      } else {
        this.formattedNumber = number.format();
        this.$emit("input", numberValue);
      }
    },
    filterInput(value: KeyboardEvent) {
      if (value.key.match(/[^-.0-9,]/g)) value.preventDefault();
    },
    filterDeletion(event: KeyboardEvent) {
      if (this.formattedNumber == "0") event.preventDefault();
    }
  },
  watch: {
    value() {
      this.formattedNumber = numeral(this.value).format();
    }
  }
});
