
import Vue from "vue";

export enum Kind {
  create,
  createPortfolio,
  edit,
  delete,
  view,
  save,
  cancel,
  inform,
  confirm,
  listDocuments,
  pdf,
  roles,
  uploadImage,
  send
}

export default Vue.extend({
  props: {
    kind: Number as () => Kind,
    disabled: Boolean,
    iconButton: Boolean,
    text: String,
    to: String
  },
  computed: {
    color() {
      switch (this.kind) {
        case Kind.create:
          return "accent";
        case Kind.createPortfolio:
          return "accent";
        case Kind.edit:
          return "tertiary";
        case Kind.delete:
          return "error";
        case Kind.view:
          return "accent";
        case Kind.save:
          return "primary";
        case Kind.cancel:
          return "error";
        case Kind.inform:
          return "accent";
        case Kind.confirm:
          return "primary";
        case Kind.listDocuments:
          return "primary";
        case Kind.pdf:
          return "primary";
        case Kind.roles:
          return "primary";
        case Kind.uploadImage:
          return "primary";
        case Kind.send:
          return "accent";
        default:
          return "primary";
      }
    },
    icon() {
      switch (this.kind) {
        case Kind.create:
          return "mdi-plus-thick";
        case Kind.createPortfolio:
          return "mdi-briefcase-plus-outline";
        case Kind.edit:
          return "mdi-pencil-outline";
        case Kind.delete:
          return "mdi-delete-outline";
        case Kind.view:
          return "mdi-eye-outline";
        case Kind.save:
          return "mdi-content-save-outline";
        case Kind.cancel:
          return "mdi-close-outline";
        case Kind.inform:
          return "mdi-text-box-check-outline";
        case Kind.confirm:
          return "mdi-check-outline";
        case Kind.listDocuments:
          return "mdi-text-box-multiple-outline";
        case Kind.pdf:
          return "mdi-file-pdf-box";
        case Kind.roles:
          return "mdi-badge-account-outline";
        case Kind.uploadImage:
          return "mdi-cloud-upload-outline";
        case Kind.send:
          return "mdi-send";
        default:
          return "mdi-pencil";
      }
    },
    tooltip() {
      let tooltipText = "";
      if (this.text) return this.text;
      switch (this.kind) {
        case Kind.create:
          tooltipText = "Crear";
          break;
        case Kind.createPortfolio:
          tooltipText = "Crear Cartera";
          break;
        case Kind.edit:
          tooltipText = "Editar";
          break;
        case Kind.delete:
          tooltipText = "Eliminar";
          break;
        case Kind.view:
          tooltipText = "Ver";
          break;
        case Kind.save:
          tooltipText = "Guardar";
          break;
        case Kind.cancel:
          tooltipText = "Cancelar";
          break;
        case Kind.inform:
          tooltipText = "Informar contacto no agendado";
          break;
        case Kind.confirm:
          tooltipText = "Confirmar";
          break;
        case Kind.listDocuments:
          tooltipText = "Documentos Asociados";
          break;
        case Kind.pdf:
          tooltipText = "Descargar PDF";
          break;
        case Kind.roles:
          tooltipText = "Administrar Roles";
          break;
        case Kind.uploadImage:
          tooltipText = "Subir Nueva Imagen";
          break;
        default:
          tooltipText = "Sin texto definido";
          break;
      }
      if (this.disabled) tooltipText = `${tooltipText} (No disponible)`;
      return tooltipText;
    }
  }
});
